import React, { RefObject } from 'react'

import ChevronDownIcon from '../icons/ChevronDown'
import LanguageSelection from '../LanguageSelection'

interface Props {
  name: string | null
  email: string | null
  toggleUserMenu: () => void
  userIconRef: RefObject<HTMLDivElement>
  closeMenu: () => void
  language: Language
  toggleLanguage: () => void
}

const UserMenu: React.FC<Props> = (props) => {
  return (
    <span className="flex h-full items-center" ref={props.userIconRef}>
      <span className="hidden items-center md:flex">
        <span className="ml-4 flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-jgp-secondary-light font-bold text-white">
          {props.name?.charAt(0).toUpperCase()}
        </span>
        <span className="ml-4 text-xs">{props.name ?? props.email}</span>
        <button
          onClick={props.toggleUserMenu}
          className="ml-4 focus:outline-none"
        >
          <ChevronDownIcon className="h-6 w-6" />
        </button>
      </span>
      <div className="flex items-center md:hidden">
        <span className="ml-4 flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-jgp-secondary-light font-bold text-white">
          {props.name?.charAt(0).toUpperCase()}
        </span>
        <button
          onClick={props.toggleUserMenu}
          className="ml-4 focus:outline-none"
        >
          <ChevronDownIcon className="h-6 w-6" />
        </button>
      </div>
      <LanguageSelection
        language={props.language}
        toggleLanguage={props.toggleLanguage}
      />
    </span>
  )
}

export default UserMenu
