import classNames from 'classnames'
import React from 'react'

import ButtonWithLoading from '../ButtonWithLoading'
import { DownloadIcon } from '../icons'

interface Props {
  className?: string
  onClick: () => Promise<void>
}

const DownloadButton: React.FC<Props> = (props) => (
  <ButtonWithLoading
    className={classNames('focus:outline-none', props.className)}
    innerClass="flex flex-col items-center text-white md:flex-row md:items-center"
    onClick={props.onClick}
    spinnerProps={{ className: 'text-white', size: 24 }}
  >
    <DownloadIcon className="mr-1.5 h-6 w-6" />
    <span className="mt-1 text-xs md:text-base lg:mt-0">Download</span>
  </ButtonWithLoading>
)

export default DownloadButton
