import { RegularChart } from '@jgp-er-dev/charts'
import classNames from 'classnames'
import { TFunction } from 'i18next'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getCommonPieChartOptions } from '../../utils/constants'
import { useDevice } from '../../utils/hooks'

export interface Props {
  data: {
    environmental: ESGPieChartSectionData
    social: ESGPieChartSectionData
    governance: ESGPieChartSectionData
  }
  className?: string
  title?: string
  device?: Device
}

interface ESGPieChartSectionData {
  weight: number
  score?: number
  subsections: ESGPieChartSubsectionData[]
}

interface ESGPieChartSubsectionData {
  name: string
  weight: number
}

const formatData = (data: Props['data']) => {
  const weightSum =
    data.environmental.weight + data.social.weight + data.governance.weight

  return [
    {
      name: 'Environmental',
      y: (100 * data.environmental.weight) / weightSum,
      drilldown: 'environmental',
      color: '#30ba09',
    },
    {
      name: 'Social',
      y: (100 * data.social.weight) / weightSum,
      drilldown: 'social',
      color: '#16164d',
    },
    {
      name: 'Governance',
      y: (100 * data.governance.weight) / weightSum,
      drilldown: 'governance',
      color: '#7e7f74',
    },
  ]
}

const formatSubsectionData = (data: ESGPieChartSubsectionData[]) => {
  const weightSum = data.reduce((acc, subsection) => acc + subsection.weight, 0)

  return data.map((subsection) => ({
    name: subsection.name,
    y: (100 * subsection.weight) / weightSum,
  }))
}

export const getChartConfig = (props: Props, t: TFunction) => {
  const series = [
    {
      name: t('Commons.weight'),
      data: formatData(props.data),
      dataLabels: {
        formatter: function () {
          const _this = this as unknown as {
            y: number
            color: string
            key: string
          }

          const sectionData =
            props.data[_this.key.toLocaleLowerCase() as keyof Props['data']]

          return `
              <div class="flex -translate-y-1/2 transform flex-col items-center no-underline">
                <span class="text-2xl" style="color: ${_this.color}">
                  ${_this.key.charAt(0)}
                </span>
                <div class="flex w-16">
                  <span class="w-10">Peso:</span>
                  <span style="color: ${_this.color}">${_this.y.toFixed(
                    0
                  )}%</span>
                </div>
                ${
                  sectionData.score !== undefined
                    ? '<div class="flex w-16"><span class="w-10">Score:</span><span style="color:' +
                      _this.color +
                      ';">' +
                      sectionData.score.toFixed(0) +
                      '</span></div>'
                    : ''
                }
              </div>
            `
        },
        useHTML: true,
        distance: 20,
        connectorWidth: 0,
      },
      tooltip: { valueSuffix: '%', valueDecimals: 2 },
    },
  ]

  const dataLabels = {
    format: '{point.y:.0f}%',
    distance: 5,
    connectorWidth: 0,
  }

  const tooltip = { valueSuffix: '%', valueDecimals: 2 }

  const drilldownSeries = [
    {
      name: t('Commons.weight'),
      id: 'environmental',
      data: formatSubsectionData(props.data.environmental.subsections),
      dataLabels,
      tooltip,
    },
    {
      name: t('Commons.weight'),
      id: 'social',
      data: formatSubsectionData(props.data.social.subsections),
      dataLabels,
      tooltip,
    },
    {
      name: t('Commons.weight'),
      id: 'governance',
      data: formatSubsectionData(props.data.governance.subsections),
      dataLabels,
      tooltip,
    },
  ]

  return {
    options: {
      ...getCommonPieChartOptions(props.device),
      title: { text: props.title ?? t('Commons.weights') },
      series: series as any,
      drilldown: {
        breadcrumbs: {
          showFullPath: false,
          format: `◁ ${t('Commons.goBack')}`,
        },
        activeDataLabelStyle: {
          color: undefined,
          textDecoration: 'none',
        },
        series: drilldownSeries as any,
        drillUpButton: {
          position: {
            x: 10,
            y: 0,
          },
        },
      },
    },
  }
}

const ESGPieChart: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { device } = useDevice()
  const chartProps = useMemo(
    () => getChartConfig({ ...props, device }, t),
    [props, device, t]
  )

  return (
    <div
      className={classNames('grid h-chart grid-cols-1 p-2', props.className)}
    >
      <RegularChart chartProps={chartProps} />
    </div>
  )
}

export default ESGPieChart
