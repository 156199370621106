import 'ag-grid-enterprise'

import type {
  CellClassParams,
  ColDef,
  ValueFormatterParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useWatchLoading } from '../../../../contexts/Loading'
import styles from './DataTable.module.css'

interface Props {
  loading: boolean
  companies: string[]
  rowData: RowData[]
  className?: string
}

export interface RowData {
  section: ESGSection | null
  indicator: string
  unit: string
  companyValues: Record<string, number | null>
}

const valueFormatter = (params: ValueFormatterParams) =>
  params.value === null || params.value === undefined
    ? '-'
    : params.value >= 100
      ? params.value.toFixed(0)
      : params.value.toFixed(2)

const DataTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  useWatchLoading(props.loading)

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'indicator',
        headerName: t('Commons.indicator'),
        pinned: 'left',
        cellClass: 'bg-opacity-40',
        cellClassRules: {
          'bg-jgp-success-light': (params: CellClassParams) =>
            params.data.section === 'environmental',
          'bg-jgp-info-light': (params: CellClassParams) =>
            params.data.section === 'social',
          'bg-jgp-secondary-light': (params: CellClassParams) =>
            params.data.section === 'governance',
        },
      },
      {
        field: 'unit',
        headerName: t('Commons.metric'),
        pinned: 'left',
      },
      ...props.companies.map((company) => ({
        field: `companyValues.${company}`,
        headerName: company,
        valueFormatter,
        flex: 1,
        minWidth: 110,
      })),
    ],
    [props.companies, t]
  )

  return props.loading ? null : (
    <div
      className={classNames(
        'ag-theme-alpine pt-8 pb-20 pl-10 pr-4',
        props.className,
        styles.container
      )}
    >
      <AgGridReact rowData={props.rowData} columnDefs={columnDefs} />
    </div>
  )
}

export default DataTable
