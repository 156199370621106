import classNames from 'classnames'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentIcon } from '../icons'
import LinkGridLink from './LinkGridLink'

interface Props {
  className?: string
  links: {
    label: string
    url: string | null
  }[]
}

const LinkGrid: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(
        'grid grid-cols-1 gap-2 rounded-md border-2 border-dashed border-gray-300 p-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5',
        props.className
      )}
    >
      {props.links.map((link) => (
        <LinkGridLink
          key={link.label}
          className={classNames('flex focus:outline-none', {
            'bg-jgp-success-main hover:bg-jgp-success-light focus-visible:bg-jgp-success-light':
              !!link.url,
            'bg-jgp-secondary-main': !link.url,
          })}
          url={link.url}
        >
          <span
            className="flex flex-grow items-center justify-between rounded-sm px-2 py-1 text-sm text-white"
            title={
              !!link.url
                ? t('Components.LinkGrid.openReferenceForm')
                : t('Components.LinkGrid.referenceFormNotAvailable')
            }
          >
            <span>{link.label}</span>
            <DocumentIcon className="h-4 w-4" />
          </span>
        </LinkGridLink>
      ))}
    </div>
  )
}

export default LinkGrid
