import classNames from 'classnames'
import React, { useMemo } from 'react'

import { useUser } from '../../contexts/User'
import SidebarSection, { Section } from './SidebarSection'

interface Props {
  className?: string
  basePath: string
  sections: Section[]
  toggleSidebar?: () => void
}

const ReportSidebar: React.FC<Props> = (props) => {
  const { checkPermissions } = useUser()

  const filteredSections = useMemo(
    () =>
      props.sections
        .filter((section) => checkPermissions(section.permissions ?? []))
        .map((section) => ({
          ...section,
          subsections: section.subsections?.filter((subsection) =>
            checkPermissions(subsection.permissions ?? [])
          ),
        })),
    [checkPermissions, props.sections]
  )

  const handleOptionClick = () => {
    if (props.toggleSidebar) {
      props.toggleSidebar()
    }
  }

  return (
    <div className={classNames('h-full w-64 bg-white', props.className)}>
      {filteredSections.map((section) => (
        <SidebarSection
          key={section.path}
          section={section}
          basePath={props.basePath}
          onClick={handleOptionClick}
        />
      ))}
    </div>
  )
}

export default ReportSidebar
