import { clone } from 'ramda'
import { createRef, FunctionComponent, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'
import { usePrevious } from '../../../../utils/hooks'
import {
  CUSTOM_ESTIMATE_YEARS,
  getConsolidatedDate,
  getInitialCustomEstimates,
} from '../../utils/remuneration'

interface Props {
  className?: string
  data: RemunerationData
  estimates: Record<string, RemunerationEstimates>
  onChange?: (newEstimates: Record<string, RemunerationEstimates>) => any
}

const INVALID_NUMBER_CLASS = 'border-jgp-error-dark'

const formatValue = (value: number | null) =>
  value === null ? '' : (value / 1e6).toFixed(2)

const isNumeric = (str: string) => !isNaN(+str) && !isNaN(parseFloat(str))

const RemunerationCustomEstimates: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation()

  const originalEstimates = useMemo(
    () =>
      Object.fromEntries(
        CUSTOM_ESTIMATE_YEARS.map((year) => [
          year,
          getInitialCustomEstimates(props.data, year),
        ])
      ),
    [props.data]
  )

  const companyData = useMemo(() => {
    const createInputRefs = () =>
      Object.fromEntries(
        CUSTOM_ESTIMATE_YEARS.map((year) => [
          year,
          createRef<HTMLInputElement>(),
        ])
      )

    return Object.entries(props.data).map(([ticker, data]) => ({
      ticker,
      name: data.company.name,
      directorsRemunerationInputRefs: createInputRefs(),
      councilRemunerationInputRefs: createInputRefs(),
    }))
  }, [props.data])

  const prevEstimates = usePrevious(props.estimates)

  // Runs when estimates change due to a report being loaded
  useEffect(() => {
    if (prevEstimates === props.estimates) {
      return
    }

    CUSTOM_ESTIMATE_YEARS.forEach((year) =>
      companyData.forEach((company) => {
        const directorsInput =
          company.directorsRemunerationInputRefs[year].current
        const councilInput = company.councilRemunerationInputRefs[year].current

        if (directorsInput) {
          directorsInput.value = formatValue(
            props.estimates[year][company.ticker].directorsRemuneration
          )
        }

        if (councilInput) {
          councilInput.value = formatValue(
            props.estimates[year][company.ticker].councilRemuneration
          )
        }
      })
    )
  }, [props.estimates, prevEstimates, companyData])

  const handleApply = () => {
    let hasErrors = false

    const newEstimates = clone(props.estimates)

    CUSTOM_ESTIMATE_YEARS.forEach((year) =>
      companyData.forEach((company) => {
        const directorsInput =
          company.directorsRemunerationInputRefs[year].current
        const councilInput = company.councilRemunerationInputRefs[year].current

        if (directorsInput) {
          if (!isNumeric(directorsInput.value)) {
            directorsInput.classList.add(INVALID_NUMBER_CLASS)
            hasErrors = true
          } else {
            directorsInput.classList.remove(INVALID_NUMBER_CLASS)
            newEstimates[year][company.ticker].directorsRemuneration =
              parseFloat(directorsInput.value) * 1e6
          }
        }

        if (councilInput) {
          if (!isNumeric(councilInput.value)) {
            councilInput.classList.add(INVALID_NUMBER_CLASS)
            hasErrors = true
          } else {
            councilInput.classList.remove(INVALID_NUMBER_CLASS)
            newEstimates[year][company.ticker].councilRemuneration =
              parseFloat(councilInput.value) * 1e6
          }
        }
      })
    )

    if (hasErrors) {
      return
    }

    props.onChange?.(newEstimates)
  }

  const handleReset = () => {
    CUSTOM_ESTIMATE_YEARS.forEach((year) =>
      companyData.forEach((company) => {
        const directorsInput =
          company.directorsRemunerationInputRefs[year].current
        const councilInput = company.councilRemunerationInputRefs[year].current

        if (directorsInput) {
          directorsInput.value = formatValue(
            originalEstimates[year][company.ticker].directorsRemuneration
          )
        }

        if (councilInput) {
          councilInput.value = formatValue(
            originalEstimates[year][company.ticker].councilRemuneration
          )
        }
      })
    )
  }

  return (
    <div className={props.className}>
      <div className="flex flex-col gap-y-12">
        {CUSTOM_ESTIMATE_YEARS.map((year) => (
          <div key={year}>
            <div className="text-lg">
              {t('Pages.ThemesReport.estimatesForTheYearOf')}{' '}
              {getConsolidatedDate(year)}:
            </div>
            <div className="mt-4 grid grid-cols-2 gap-4">
              {companyData.map(
                (company) =>
                  props.estimates[year]?.[company.ticker] && (
                    <div key={company.ticker}>
                      <div className="font-bold">{company.name}</div>
                      <div className="grid grid-cols-[max-content_12rem] gap-2">
                        <span>
                          {t('Pages.ThemesReport.boardRemunerationBRLmi')}:
                        </span>
                        <input
                          ref={company.directorsRemunerationInputRefs[year]}
                          className="border px-1"
                          defaultValue={formatValue(
                            props.estimates[year][company.ticker]
                              .directorsRemuneration
                          )}
                        />
                        <span>
                          {t('Pages.ThemesReport.councilRemunerationBRLmi')}:
                        </span>
                        <input
                          ref={company.councilRemunerationInputRefs[year]}
                          className="border px-1"
                          defaultValue={formatValue(
                            props.estimates[year][company.ticker]
                              .councilRemuneration
                          )}
                        />
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-12 flex justify-end gap-x-2">
        <Button className="h-8 w-28" variant="primary" onClick={handleApply}>
          {t('Pages.ThemesReport.apply')}
        </Button>
        <Button className="h-8 w-28" variant="secondary" onClick={handleReset}>
          {t('Pages.ThemesReport.reset')}
        </Button>
      </div>
    </div>
  )
}

export default RemunerationCustomEstimates
