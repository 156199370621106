import { useTranslation } from 'react-i18next'

import { useWatchLoading } from '../../../../contexts/Loading'
import type { QualiIndicator } from '../QualiIndicatorTable'
import QualiIndicatorTable from '../QualiIndicatorTable'

interface Props {
  className?: string
  loading: boolean
  companyName: string
  isSingleTopic?: boolean
  topics: {
    name: string
    indicators: QualiIndicator[]
  }[]
}

const DecarbonizationIndicators: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  useWatchLoading(props.loading)

  return props.loading ? null : (
    <div className={props.className}>
      <div className="font-bold">{t('Commons.qualitative')}</div>
      {props.topics.length > 0 ? (
        <QualiIndicatorTable
          className="mt-10"
          companyName={props.companyName}
          topics={props.topics}
          isSingleTopic={props.isSingleTopic}
        />
      ) : (
        <div className="flex items-center justify-center py-8 text-gray-400">
          {t('Commons.noDataToDisplay')}
        </div>
      )}
    </div>
  )
}

export default DecarbonizationIndicators
