import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ScoreHistoryChart, {
  Props as ScoreHistoryChartProps,
} from '../../../../components/ScoreHistoryChart'
import SubsectionPieChart, {
  Props as SubsectionPieChartProps,
} from '../../../../components/SubsectionPieChart'
import { useWatchLoading } from '../../../../contexts/Loading'
import SubsectionScoresChart, {
  Props as SubsectionScoresChartProps,
} from '../SubsectionScoresChart'

interface Props {
  loading: boolean
  subsectionPieChartProps: SubsectionPieChartProps | null
  scoreHistoryChartProps: ScoreHistoryChartProps | null
  subsectionScoresChartProps: SubsectionScoresChartProps | null
  className?: string
}

const ESGSectionOverview: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  useWatchLoading(props.loading)

  return props.loading ? null : (
    <div className={classNames('font-work-sans', props.className)}>
      <div className="text-xl font-semibold">{t('Commons.overview')}</div>
      <div className="mt-12 grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-4">
        <SubsectionPieChart
          className="mx-auto w-full md:col-span-2"
          {...props.subsectionPieChartProps!}
        />
        <ScoreHistoryChart
          className="w-full"
          {...props.scoreHistoryChartProps!}
        />
        <SubsectionScoresChart
          className="w-full"
          {...props.subsectionScoresChartProps!}
        />
      </div>
    </div>
  )
}

export default ESGSectionOverview
