import classNames from 'classnames'
import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ColumnChart from '../../../../components/ColumnChart'
import { ArrowSmRightIcon } from '../../../../components/icons'
import { useWatchLoading } from '../../../../contexts/Loading'
import { sectionIdToLabel } from '../../../../utils'
import { ESG_SECTIONS } from '../../../../utils/constants'

interface Props {
  className?: string
  loading: boolean
  goals: CompanyGoal[]
}

export interface CompanyGoal {
  type: ESGSection
  description: string
  comments: string | null
  source: string | null
  data: {
    referenceDate: number
    value: number
  }[]
  goals: {
    referenceDate: number
    value: number
  }[]
  hasIndicator: boolean
  indicator: string | null
  unitOfMeasurement: string | null
  isSLB: boolean
  baseline: string | null
  stepUp: string | null
  jgpRating: string | null
}

const filterByType = (goals: CompanyGoal[], type: CompanyGoal['type']) =>
  goals.filter((goal) => goal.type === type)

const renderSection = (goals: CompanyGoal[], type: CompanyGoal['type']) =>
  goals.length > 0 ? (
    <div>
      <div className="text-2xl">{sectionIdToLabel(type)}</div>
      <div className="mt-4 flex flex-col gap-8">
        {goals.map((goal) => (
          <div
            key={goal.description}
            className="grid grid-cols-[min-content_auto] items-center gap-x-2"
          >
            <ArrowSmRightIcon className="h-8 w-8 text-jgp-success-dark" />
            <div className="text-lg">{goal.description}</div>
            <div className="col-start-2">
              {goal.hasIndicator && (
                <Fragment>
                  <ColumnChart
                    type="vertical"
                    title={goal.indicator ?? undefined}
                    data={[
                      ...goal.data.map((data) => ({
                        name: data.referenceDate.toString(),
                        value: data.value,
                        type: 'default' as const,
                      })),
                      ...goal.goals.map((data) => ({
                        name: `${data.referenceDate.toString()} (meta)`,
                        value: data.value,
                        type: 'highlighted' as const,
                      })),
                    ]}
                    unit={goal.unitOfMeasurement ?? undefined}
                    dataLabels
                  />
                </Fragment>
              )}
              <div>SLB: {goal.isSLB ? 'Sim' : 'Não'}</div>
              {goal.isSLB && (
                <Fragment>
                  <div>Baseline: {goal.baseline ?? ''}</div>
                  <div>Step-up: {goal.stepUp ?? ''}</div>
                  <div>Rating de Adequação JGP: {goal.jgpRating ?? ''}</div>
                </Fragment>
              )}
              {goal.comments && <div>Comentários: {goal.comments}</div>}
              {goal.source && <div>Fonte: {goal.source}</div>}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null

const CompanyGoals: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  useWatchLoading(props.loading)

  const goalsByType = useMemo(
    () =>
      Object.fromEntries(
        ESG_SECTIONS.map((type) => [type, filterByType(props.goals, type)])
      ),
    [props.goals]
  ) as Record<ESGSection, CompanyGoal[]>

  return props.loading ? null : (
    <div
      className={classNames(
        'pt-4 pb-20 pl-10 pr-4 font-work-sans',
        props.className
      )}
    >
      <div className="text-3xl font-semibold">{t('CompanyReport.goals')}</div>
      {props.goals.length === 0 && (
        <div className="flex items-center justify-center py-8 text-gray-400">
          {t('Commons.noDataToDisplay')}
        </div>
      )}
      <div className="mt-8 flex flex-col gap-8">
        {ESG_SECTIONS.map((section) =>
          renderSection(goalsByType[section], section)
        )}
      </div>
    </div>
  )
}

export default CompanyGoals
