import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ControversyList from '../../../../components/ControversyList'

interface Props {
  controversies: Controversy[]
}

const Controversies: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const filterBySection = useCallback(
    (section: ESGSection) =>
      props.controversies.filter(
        (controversy) => controversy.section === section
      ),
    [props.controversies]
  )

  const environmentalControversies = useMemo(
    () => filterBySection('environmental'),
    [filterBySection]
  )
  const socialControversies = useMemo(
    () => filterBySection('social'),
    [filterBySection]
  )
  const governanceControversies = useMemo(
    () => filterBySection('governance'),
    [filterBySection]
  )

  if (props.controversies.length === 0) {
    return (
      <div className="pb-20 pl-10 pr-4">
        {t('Pages.CompanyReport.noControversiesForThisCompany')}
      </div>
    )
  }

  return (
    <div className="flex justify-center pt-4 pb-20 pl-10 pr-4">
      <div className="max-w-screen-md flex-grow">
        {environmentalControversies.length > 0 && (
          <ControversyList
            className="mb-4"
            title={t('Pages.CompanyReport.ambiental')}
            list={environmentalControversies}
          />
        )}
        {socialControversies.length > 0 && (
          <ControversyList
            className="mb-4"
            title={t('Pages.CompanyReport.social')}
            list={socialControversies}
          />
        )}
        {governanceControversies.length > 0 && (
          <ControversyList
            className="mb-4"
            title={t('Pages.CompanyReport.governance')}
            list={governanceControversies}
          />
        )}
      </div>
    </div>
  )
}

export default Controversies
