import React, { RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Path } from '../../utils/constants'
import BellIcon from '../icons/Bell'
import CogIcon from '../icons/Cog'

interface Props {
  isUserMenuOpen: boolean
  userIconRef: RefObject<HTMLDivElement>
  name: string | null
  email: string | null
  closeDropdown: () => void
  toggleLanguage: () => void
  language: Language
}

const DropdownMenu: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  if (!props.isUserMenuOpen || !props.userIconRef.current) return null

  return (
    <div
      className="absolute z-50 bg-white shadow-md md:w-64"
      style={{
        left:
          window.innerWidth >= 768
            ? `${props.userIconRef.current.getBoundingClientRect().left}px`
            : '0px',
        top: '56px',
        width: window.innerWidth < 768 ? '100%' : '',
      }}
    >
      <div className="px-4 py-2">
        <div className="mb-4 flex items-center md:hidden">
          <span className="flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-jgp-secondary-light font-bold text-white">
            {props.name?.charAt(0).toUpperCase()}
          </span>
          <span className="ml-4 text-sm font-medium">
            {props.name ?? props.email}
          </span>
        </div>
        <Link
          to={Path.CONFIG}
          className="block py-2 text-sm"
          onClick={props.closeDropdown}
        >
          <CogIcon className="mr-2 inline-block h-5 w-5 text-jgp-secondary-light" />
          {t('Components.TopBar.userMenu.settings')}
        </Link>
        <button className="relative block py-2 text-sm focus:outline-none">
          <BellIcon className="mr-2 inline-block h-5 w-5 text-jgp-secondary-light" />
          {t('Components.TopBar.userMenu.notifications')}
        </button>
      </div>
    </div>
  )
}

export default DropdownMenu
