import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ESGPlatformHeader.module.css'

interface Props {
  className?: string
}

const ESGPlatformHeader: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(
        'flex justify-center h-20 md:h-36',
        styles.header,
        props.className
      )}
    >
      <div className="flex w-full max-w-screen-xl items-center px-4 text-xl md:text-2xl">
        <span
          className="font-bold"
          dangerouslySetInnerHTML={{
            __html: t('Components.ESGPlatformHeader.platformHeader'),
          }}
        />
      </div>
    </div>
  )
}

export default ESGPlatformHeader
