import type { ColDef, GridApi } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import classNames from 'classnames'
import { TFunction } from 'i18next'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { usePrevious } from '../../../../utils/hooks'
import { CompanyRemunerationDataHandler } from '../../utils/CompanyRemunerationDataHandler'

interface Props {
  className?: string
  data: RemunerationData
  year: string
  type: TableType
}

type TableType = 'council' | 'directors'

const REMUNERATION_MKT_CAP_RATIO_LABEL = 'Remuneração/Mkt Cap'
const REMUNERATION_EBITDA_RATIO_LABEL = 'Remuneração/EBITDA'
const REMUNERATION_PROFIT_RATIO_LABEL = 'Remuneração/Lucro'

const isRatioValue = (label: string) =>
  [
    REMUNERATION_EBITDA_RATIO_LABEL,
    REMUNERATION_MKT_CAP_RATIO_LABEL,
    REMUNERATION_PROFIT_RATIO_LABEL,
  ].includes(label)

const formatRatio = (value: number | null | undefined) =>
  value ? (100 * value).toFixed(2) + '%' : ''

const formatRemuneration = (value: number | null | undefined) =>
  value ? (value / 1e6).toFixed(2) + ' mi BRL' : ''

const RemunerationTable: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation()

  const [gridApi, setGridApi] = useState<GridApi | null>(null)

  const rowData = useMemo(() => {
    const getTableRows = (t: TFunction) => [
      {
        label: t('Pages.ThemesReport.totalCompensation'),
        getData: (
          year: string,
          data: CompanyRemunerationData,
          type: TableType
        ) =>
          new CompanyRemunerationDataHandler(data).getValue(
            year,
            `${type}Remuneration`
          ) ?? null,
      },
      {
        label: t('Pages.ThemesReport.compensationMktCap'),
        getData: (
          year: string,
          data: CompanyRemunerationData,
          type: TableType
        ) => {
          const handler = new CompanyRemunerationDataHandler(data)

          const remuneration = handler.getValue(year, `${type}Remuneration`)
          const mktcap = handler.getValue(year, 'mktcap', {
            allowConsolidatedValueForEstimatedYear: true,
          })

          return remuneration !== null && mktcap !== null
            ? remuneration / mktcap
            : null
        },
      },
      {
        label: t('Pages.ThemesReport.compensationEBITDA'),
        getData: (
          year: string,
          data: CompanyRemunerationData,
          type: TableType
        ) => {
          const handler = new CompanyRemunerationDataHandler(data)

          const remuneration = handler.getValue(year, `${type}Remuneration`)
          const ebitda = handler.getValue(year, 'ebitda', {
            allowConsolidatedValueForEstimatedYear: true,
          })

          return remuneration !== null && ebitda !== null
            ? remuneration / ebitda
            : null
        },
      },
      {
        label: t('Pages.ThemesReport.compensationProfit'),
        getData: (
          year: string,
          data: CompanyRemunerationData,
          type: TableType
        ) => {
          const handler = new CompanyRemunerationDataHandler(data)

          const remuneration = handler.getValue(year, `${type}Remuneration`)
          const profit = handler.getValue(year, 'profit', {
            allowConsolidatedValueForEstimatedYear: true,
          })

          return remuneration !== null && profit !== null
            ? remuneration / profit
            : null
        },
      },
      {
        label: t('Pages.ThemesReport.averageCompensation'),
        getData: (
          year: string,
          data: CompanyRemunerationData,
          type: TableType
        ) =>
          new CompanyRemunerationDataHandler(data).getValue(
            year,
            `${type}AvgRemuneration`
          ) ?? null,
      },
    ]
    return getTableRows(t).map((row) => ({
      label: row.label,
      ...Object.fromEntries(
        Object.values(props.data).map((data) => [
          data.company.ticker,
          row.getData(props.year, data, props.type),
        ])
      ),
    }))
  }, [t, props.data, props.year, props.type])

  const prevRowData = usePrevious(rowData)

  useEffect(() => {
    if (rowData !== prevRowData) {
      setTimeout(() => gridApi?.autoSizeAllColumns(), 10)
    }
  })

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'label',
        headerName: '',
        cellClass: 'bg-jgp-success-main text-white text-left',
      },
      ...Object.values(props.data).map(
        (data): ColDef => ({
          field: data.company.ticker,
          headerName: data.company.name,
          valueFormatter: (params) =>
            isRatioValue(params.data.label)
              ? formatRatio(params.value)
              : formatRemuneration(params.value),
        })
      ),
    ],
    [props.data]
  )

  return (
    <div className={classNames('ag-theme-alpine-compact', props.className)}>
      <AgGridReact
        rowData={rowData}
        domLayout="autoHeight"
        onGridReady={(event) => {
          setGridApi(event.api)
          event.api.autoSizeAllColumns()
        }}
        columnDefs={columnDefs}
      />
    </div>
  )
}

export default RemunerationTable
