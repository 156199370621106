import React from 'react'

import ScoreCard from '../../../../components/ScoreCard'

export interface ScoreCardData {
  title: string
  value: string
  color: 'primary' | 'secondary'
  className: string
}

interface Props {
  cards: ScoreCardData[]
}

const ScoreCards: React.FC<Props> = (props) => {
  return (
    <div className="mt-8 grid auto-cols-max grid-flow-col grid-cols-1 justify-end justify-items-end gap-4">
      {props.cards.map((card, index) => (
        <ScoreCard
          key={index}
          color={card.color}
          title={card.title}
          value={card.value}
          className={card.className}
        />
      ))}
    </div>
  )
}

export default ScoreCards
