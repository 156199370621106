import React from 'react'
import { useTranslation } from 'react-i18next'

import ColumnChart, {
  Props as ColumnChartProps,
} from '../../../../components/ColumnChart'
import ScoreHistoryChart, {
  Props as ScoreHistoryChartProps,
} from '../../../../components/ScoreHistoryChart'

interface Props {
  data: [ColumnChartProps, ScoreHistoryChartProps][]
  className?: string
}

const ESGSubsubsection: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <div className={props.className}>
      <div className="font-bold">{t('Commons.quantitative')}</div>
      <div className="mt-10 grid gap-y-16 md:grid-cols-2 lg:gap-x-4">
        {props.data.map((indicator) => {
          return (
            <React.Fragment key={indicator[0].title}>
              <ColumnChart {...indicator[0]} />
              <ScoreHistoryChart {...indicator[1]} />
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
export default ESGSubsubsection
