import { RegularChart } from '@jgp-er-dev/charts'
import classNames from 'classnames'
import { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { colorPalette } from '../../../../utils/constants'

interface Props {
  className?: string
  config: TimeSeriesChartConfig
}

export interface TimeSeriesChartConfig {
  type: 'timeseries'
  series: {
    name: string
    data: { year: string; value: number; isEstimate?: boolean }[]
  }[]
  years: string[]
  title: string
  unit?: string
  decimals?: number
}

const CONSOLIDATED_DASH_STYLE = 'Solid'
const ESTIMATED_DASH_STYLE = 'ShortDash'

const computeZones = (
  data: { year: string; isEstimate?: boolean }[],
  years: string[]
) => {
  let curZoneIsEstimate = false
  const zones: { value?: number; dashStyle: string }[] = [
    { dashStyle: CONSOLIDATED_DASH_STYLE },
  ]

  data.forEach(({ year, isEstimate }) => {
    if (
      (isEstimate && !curZoneIsEstimate) ||
      (!isEstimate && curZoneIsEstimate)
    ) {
      zones[zones.length - 1].value = years.findIndex((y) => y === year) - 1
      curZoneIsEstimate = !curZoneIsEstimate
      zones.push({
        dashStyle: curZoneIsEstimate
          ? ESTIMATED_DASH_STYLE
          : CONSOLIDATED_DASH_STYLE,
      })
    }
  })

  return zones
}

const RemunerationTimeSeriesChart: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation()

  const chartProps = useMemo(() => {
    const series = props.config.series.map((s) => ({
      name: s.name,
      type: 'line',
      data: s.data.map((point) => ({
        name: point.year,
        y: point.value,
        isEstimate: point.isEstimate,
      })),
      tooltip: {
        valueSuffix: props.config.unit && ` ${props.config.unit}`,
        valueDecimals: props.config.decimals ?? 0,
      },
      marker: {
        enabled: s.data.length === 1,
      },
      zoneAxis: 'x',
      zones: computeZones(s.data, props.config.years),
    }))

    return {
      options: {
        chart: { style: { fontFamily: 'Work Sans' } },
        colors: colorPalette,
        series: series as any,
        yAxis: {
          id: 'default',
          opposite: false,
          softMin: 0,
          title: {
            align: 'high' as const,
            text: props.config.unit ? `(${props.config.unit})` : undefined,
            rotation: 0,
            offset: 15,
            y: -20,
          },
        },
        xAxis: {
          type: 'category' as const,
          categories: props.config.years,
        },
        tooltip: {
          formatter: function (tooltip: any) {
            const _this = this as unknown as {
              point: {
                options: { isEstimate?: boolean }
              }
            }

            const prefix = _this.point.options.isEstimate
              ? [
                  '<span className="text-[10px] italic">(Estimativa)</span><br/>',
                ]
              : []

            return prefix.concat(tooltip.defaultFormatter.call(this, tooltip))
          },
        },
        title: {
          text: props.config.title,
          style: { fontSize: '14px' },
        },
        credits: { text: '' },
      },
    }
  }, [props.config])

  return (
    <div
      className={classNames(
        'relative grid h-chart grid-cols-1 p-2',
        props.className
      )}
    >
      <RegularChart chartProps={chartProps} />
      {props.config.years.length === 0 && (
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-sm text-gray-500">
            {t('Commons.noDataToDisplay')}
          </span>
        </div>
      )}
    </div>
  )
}

export default RemunerationTimeSeriesChart
