import type { PermissionName } from '@jgp-er-dev/permissions'

import { Path } from '../../utils/constants'

export interface LinkConfig {
  labelKey: string
  path: string
  permission?: PermissionName
}

export const LINK_CONFIG: LinkConfig[] = [
  {
    labelKey: 'Components.TopBar.options.home',
    path: Path.REPORTS,
  },
  {
    labelKey: 'Commons.companies',
    path: Path.REPORTS_COMPANY,
  },
  {
    labelKey: 'Commons.sectors',
    path: Path.REPORTS_SECTORAL,
  },
  {
    labelKey: 'Commons.statistics',
    path: Path.REPORTS_STATISTICS,
    permission: 'v2:esg_reports:score_statistics',
  },
  {
    labelKey: 'Components.TopBar.options.customize',
    path: Path.REPORTS_PERSONALIZED,
    permission: 'v2:esg_reports:personalized_reports',
  },
  {
    labelKey: 'Commons.themes',
    path: Path.REPORTS_THEMES,
    permission: 'v2:esg_reports:themes',
  },
  {
    labelKey: 'Commons.help',
    path: '#',
  },
]
