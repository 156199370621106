import React from 'react'
import { useTranslation } from 'react-i18next'

import WarningIcon from '../icons/Warning'

const NotFound: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex flex-col items-center text-gray-400">
        <WarningIcon className="h-24 w-24" />
        <span className="text-2xl">
          {t('Components.NotFound.pageNotFound')}
        </span>
      </div>
    </div>
  )
}

export default NotFound
