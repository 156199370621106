import type { PermissionName } from '@jgp-er-dev/permissions'
import React from 'react'

import LinksList from './LinksList'

interface Props {
  isMenuOpen: boolean
  checkPermissions: (permissions: PermissionName[]) => boolean
  closeMenu: () => void
}

const MobileMenu: React.FC<Props> = (props) => {
  if (!props.isMenuOpen) return null

  return (
    <div className="fixed top-14 left-0 z-50 w-full bg-white shadow-md md:hidden">
      <div className="space-y-4 px-4 py-2">
        <LinksList
          checkPermissions={props.checkPermissions}
          closeMenu={props.closeMenu}
        />
      </div>
    </div>
  )
}

export default MobileMenu
