import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'

interface Props {
  currentName: string
  reportNames: string[]
  onRename?: (name: string) => any
  onClose: () => any
}

const RenameModal: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [error, setError] = useState<string>('')

  const inputRef = useRef<HTMLInputElement>(null)

  const handleRename = () => {
    const name = inputRef.current?.value.trim()

    if (name === props.currentName) {
      props.onClose()
      return
    }

    if (!name) {
      setError(t('Pages.ThemesReport.nameCannotBeEmpty'))
      return
    }

    if (name !== props.currentName && props.reportNames.includes(name)) {
      setError(
        t('Pages.ThemesReport.aDifferentReportWithThisNameAlreadyExists')
      )
      return
    }

    props.onRename?.(name)
    props.onClose()
  }

  return (
    <Modal open onClose={props.onClose}>
      <div className="max-w-screen min-w-[40rem] p-8">
        <div>{t('Pages.ThemesReport.enterNewReportName')}:</div>
        <div className="relative mt-4">
          <input
            className="w-full border px-1"
            ref={inputRef}
            defaultValue={props.currentName}
            onChange={() => setError('')}
            onKeyDown={(event) => event.key === 'Enter' && handleRename()}
          />
          <div className="absolute bottom-0 translate-y-full text-jgp-error-dark">
            {error}
          </div>
        </div>
        <div className="mt-8 flex items-center justify-end gap-4">
          <Button className="h-8 w-28" variant="primary" onClick={handleRename}>
            {t('Pages.ThemesReport.rename')}
          </Button>
          <Button
            className="h-8 w-28"
            variant="secondary"
            onClick={props.onClose}
          >
            {t('Commons.cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default RenameModal
