import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUser } from '../../contexts/User'
import DownloadButton from './DownloadButton'
import FavoriteButton from './FavoriteButton'
import ManageButton from './ManageButton'
import NotificationsButton from './NotificationsButton'

interface CommonProps {
  className?: string
}

interface FavoriteButtonProps {
  isFavorite: boolean
  onFavoriteChange?: (value: boolean) => Promise<void>
}

interface DownloadButtonProps {
  onDownload?: () => Promise<void>
}

interface ManageButtonProps {
  onManage?: () => void
}

interface NotificationsButtonProps {
  isNotified: boolean
  onNotificationChange?: (value: boolean) => Promise<void>
}

interface CompanyProps
  extends CommonProps,
    FavoriteButtonProps,
    DownloadButtonProps,
    NotificationsButtonProps {
  type: 'company'
  ticker: string | null
  name: string
}

interface ComparativeProps
  extends CommonProps,
    FavoriteButtonProps,
    DownloadButtonProps,
    NotificationsButtonProps {
  type: 'comparative'
  companies: Pick<Company, 'name' | 'ticker'>[]
}

interface PersonalizedProps extends CommonProps, ManageButtonProps {
  type: 'personalized'
}

interface BasketsProps extends CommonProps, ManageButtonProps {
  type: 'baskets'
}

interface ThemesProps extends CommonProps {
  type: 'themes'
}

interface StatisticsProps extends CommonProps {
  type: 'statistics'
}

interface SectorProps
  extends CommonProps,
    FavoriteButtonProps,
    DownloadButtonProps,
    NotificationsButtonProps {
  type: 'sector'
  name: string
}

type Props =
  | BasketsProps
  | CompanyProps
  | ComparativeProps
  | PersonalizedProps
  | SectorProps
  | ThemesProps
  | StatisticsProps

const ReportHeader: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { checkPermissions } = useUser()

  const utilitiesOnly = useMemo(
    () => checkPermissions(['v2:esg_reports:utilities_only']),
    [checkPermissions]
  )

  return props.type === 'comparative' ? null : (
    <div
      className={classNames(
        'flex justify-center bg-jgp-success-main px-4 py-6 font-work-sans text-white md:py-8',
        props.className
      )}
    >
      <div className="flex w-full max-w-screen-xl items-center justify-between">
        <span className="flex flex-col">
          <span className="text-xs font-light text-white md:text-sm">
            {props.type === 'sector'
              ? t('Components.ReportHeader.sectorAnalysis')
              : props.type === 'company'
                ? t('Components.ReportHeader.businessAnalysis')
                : null}
          </span>
          <span className="text-2xl font-semibold leading-tight md:text-3xl">
            {props.type === 'personalized'
              ? t('Components.ReportHeader.customAnalysis')
              : props.type === 'baskets'
                ? 'Baskets'
                : props.type === 'themes'
                  ? t('Commons.themes')
                  : props.type === 'statistics'
                    ? t('Commons.statistics')
                    : props.name}
          </span>
        </span>
        <span className="mt-4 flex flex-col items-center md:mt-0 md:flex-row">
          {(props.type === 'personalized' || props.type === 'baskets') && (
            <ManageButton onClick={props.onManage} />
          )}
          {(props.type === 'company' || props.type === 'sector') && (
            <>
              <FavoriteButton
                onClick={async () =>
                  props.onFavoriteChange?.(!props.isFavorite)
                }
                isFavorite={props.isFavorite}
              />
              {!utilitiesOnly && (
                <DownloadButton
                  className="mt-4 md:mt-0 md:ml-6"
                  onClick={async () => props.onDownload?.()}
                />
              )}
              <NotificationsButton
                className="mt-4 md:mt-0 md:ml-6"
                onClick={async () =>
                  props.onNotificationChange?.(!props.isNotified)
                }
                isNotified={props.isNotified}
              />
            </>
          )}
        </span>
      </div>
    </div>
  )
}

export default ReportHeader
