import classNames from 'classnames'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Tooltip from 'react-tooltip'

import Button from '../../../../components/Button'
import { BasketIcon } from '../../../../components/icons'
import LabeledComponent from '../../../../components/LabeledComponent'
import { useToast } from '../../../../contexts/Toast'
import { Path } from '../../../../utils/constants'
import { GLOBAL_VARIABLES_SLUG, SELECT_STYLES } from '../../utils/constants'
import { getReportShareableUrl } from '../../utils/share'
import styles from './Settings.module.css'

interface Props {
  basket: SelectOption | null
  basketOptions: SelectOption[]
  indicator: SelectOption<string[]> | null
  indicatorOptions: SelectOption<string[]>[]
  section: SelectOption<string[]> | null
  sectionOptions: SelectOption<string[]>[]
  subsection: SelectOption<string[]> | null
  subsectionOptions: SelectOption<string[]>[]
  weight: SelectOption | null
  weightOptions: SelectOption[]
  weighting: SelectOption | null
  weightingOptions: SelectOption[]
  className?: string
  onBasketChange?: (basket: SelectOption | null) => void
  onIndicatorChange?: (indicator: SelectOption<string[]> | null) => void
  onSectionChange?: (section: SelectOption<string[]> | null) => void
  onSubsectionChange?: (subsection: SelectOption<string[]> | null) => void
  onWeightChange?: (weight: SelectOption | null) => void
  onWeightingChange?: (weighting: SelectOption | null) => void
  onSave?: () => void
  onSubmit?: () => void
  onShare?: () => Promise<string | null>
}

const BASKET_BUTTON_TOOLTIP_ID = 'basket-button'

const Settings: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const history = useHistory()
  const { enqueueToast } = useToast()

  const sectionSelectRef = useRef<any>()
  const subsectionSelectRef = useRef<any>()
  const indicatorSelectRef = useRef<any>()
  const basketSelectRef = useRef<any>()
  const weightingSelectRef = useRef<any>()
  const weightSelectRef = useRef<any>()

  const checkReportIsFilled = () => {
    if (!props.section) {
      sectionSelectRef.current?.focus()
      return false
    } else if (
      props.section?.value[0] !== GLOBAL_VARIABLES_SLUG &&
      !props.subsection
    ) {
      subsectionSelectRef.current?.focus()
      return false
    } else if (!props.indicator) {
      indicatorSelectRef.current?.focus()
      return false
    } else if (!props.basket) {
      basketSelectRef.current?.focus()
      return false
    } else if (!props.weighting) {
      weightingSelectRef.current?.focus()
      return false
    } else if (!props.weight) {
      weightSelectRef.current?.focus()
      return false
    }
    return true
  }

  const handleSubmit = () => {
    if (checkReportIsFilled()) {
      props.onSubmit?.()
    }
  }

  const handleSave = () => {
    if (checkReportIsFilled()) {
      props.onSave?.()
    }
  }

  const handleShare = () => {
    if (checkReportIsFilled()) {
      props.onShare?.().then((id) => {
        if (id) {
          navigator.clipboard.writeText(getReportShareableUrl(id)).then(() => {
            enqueueToast({
              message: t('Pages.PersonalizedReport.reportSharedMessage'),
              type: 'success',
            })
          })
        } else {
          enqueueToast({
            message: `${t('Pages.PersonalizedReport.anErrorOccurredWhileGeneratingTheShareLink')} ${t('Commons.pleaseTryAgain')}`,
            type: 'fail',
          })
        }
      })
    }
  }

  return (
    <div
      className={classNames(
        'flex justify-center bg-gray-100 py-8',
        props.className
      )}
    >
      <div className="w-full max-w-screen-xl px-4">
        <div className="flex flex-wrap gap-4">
          <LabeledComponent className="w-56" label="Seção">
            <Select
              ref={sectionSelectRef}
              styles={SELECT_STYLES}
              placeholder={t('Pages.PersonalizedReport.chooseSection')}
              options={props.sectionOptions}
              value={props.section}
              onChange={props.onSectionChange}
            />
          </LabeledComponent>
          <LabeledComponent className="w-80" label={t('Commons.subsection')}>
            <Select
              ref={subsectionSelectRef}
              styles={SELECT_STYLES}
              placeholder={t('Pages.PersonalizedReport.chooseSubsection')}
              options={props.subsectionOptions}
              value={props.subsection}
              onChange={props.onSubsectionChange}
              isDisabled={
                !props.section ||
                props.section.value[0] === GLOBAL_VARIABLES_SLUG
              }
            />
          </LabeledComponent>
          <LabeledComponent className="w-80" label={t('Commons.indicator')}>
            <Select
              ref={indicatorSelectRef}
              styles={SELECT_STYLES}
              placeholder={t('Pages.PersonalizedReport.chooseIndicator')}
              options={props.indicatorOptions}
              value={props.indicator}
              onChange={props.onIndicatorChange}
              isDisabled={
                props.section?.value[0] !== GLOBAL_VARIABLES_SLUG &&
                !props.subsection
              }
            />
          </LabeledComponent>
        </div>
        <div className="mt-4 flex flex-wrap items-end gap-4">
          <LabeledComponent className="w-80" label={t('Commons.basket')}>
            <CreatableSelect
              ref={basketSelectRef}
              styles={SELECT_STYLES}
              placeholder={t('Pages.PersonalizedReport.chooseBasket')}
              options={props.basketOptions}
              value={props.basket}
              formatCreateLabel={() =>
                t('Pages.PersonalizedReport.createNewBasket')
              }
              isValidNewOption={() => true}
              onChange={props.onBasketChange}
              onCreateOption={() =>
                history.push(Path.REPORTS_PERSONALIZED_BASKETS)
              }
            />
          </LabeledComponent>
          <LabeledComponent
            className="w-56"
            label={t('Pages.PersonalizedReport.calculation')}
          >
            <Select
              ref={weightingSelectRef}
              styles={SELECT_STYLES}
              placeholder={t('Pages.PersonalizedReport.chooseCalculation')}
              options={props.weightingOptions}
              value={props.weighting}
              onChange={props.onWeightingChange}
            />
          </LabeledComponent>
          <LabeledComponent
            className="w-56"
            label={t('Pages.PersonalizedReport.ponderation')}
          >
            <Select
              ref={weightSelectRef}
              styles={SELECT_STYLES}
              placeholder={t('Pages.PersonalizedReport.choosePonderation')}
              options={props.weightOptions}
              value={props.weight}
              onChange={props.onWeightChange}
              isDisabled={!props.weighting}
            />
          </LabeledComponent>
          <button
            className={styles.basketButton}
            data-tip
            data-for={BASKET_BUTTON_TOOLTIP_ID}
            onClick={() => history.push(Path.REPORTS_PERSONALIZED_BASKETS)}
          >
            <BasketIcon className="h-6 w-6 text-jgp-success-main" />
          </button>
          <Tooltip id={BASKET_BUTTON_TOOLTIP_ID} effect="solid" place="bottom">
            {t('Commons.manageBaskets')}
          </Tooltip>
        </div>
        <div className="mt-8 flex flex-wrap gap-4">
          <Button className="h-8 w-28" variant="primary" onClick={handleSubmit}>
            {t('Commons.analyse')}
          </Button>
          <Button className="h-8 w-28" variant="secondary" onClick={handleSave}>
            {t('Commons.save')}
          </Button>
          <Button
            className="h-8 w-28"
            variant="secondary"
            onClick={handleShare}
          >
            {t('Pages.PersonalizedReport.share')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Settings
