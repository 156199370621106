import classNames from 'classnames'
import React from 'react'

interface Props {
  title: string
  value: string
  color: 'primary' | 'secondary'
  className?: string
}

const ScoreCard: React.FC<Props> = (props) => (
  <div
    className={classNames(
      'flex flex-col justify-between items-center rounded-lg p-1.5 sm:p-5 box-border w-full sm:w-auto min-w-[75px] max-w-[75px] sm:min-w-[160px] sm:max-w-[200px] overflow-hidden',
      {
        'bg-jgp-success-main': props.color === 'primary',
        'border border-jgp-secondary-light border-opacity-50 text-jgp-secondary-main':
          props.color === 'secondary',
      },
      props.className
    )}
  >
    <span
      className={classNames('text-xs sm:text-lg font-bold truncate', {
        'text-white': props.color === 'primary',
        'text-jgp-secondary-main': props.color === 'secondary',
      })}
    >
      {props.title}
    </span>
    <span
      className={classNames(
        'text-xl sm:text-4xl font-bold leading-tight truncate',
        {
          'text-white': props.color === 'primary',
          'text-black': props.color === 'secondary',
        }
      )}
    >
      {props.value}
    </span>
  </div>
)

export default ScoreCard
