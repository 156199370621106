import type { ColDef, ValueFormatterParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { BasketCompany } from '../../typings/baskets'
import { stringComparator } from '../../utils/tables'
import styles from './CompaniesTable.module.css'

interface Props {
  companies: BasketCompany[]
  className?: string
}

const frameworksValueFormatter = (params: ValueFormatterParams) =>
  params.value.join(', ')

const CompaniesTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('Commons.company'),
        comparator: stringComparator,
      },
      {
        field: 'sector',
        headerName: t('Commons.sector'),
        comparator: stringComparator,
      },
      {
        field: 'frameworks',
        headerName: t('Commons.framework'),
        valueFormatter: frameworksValueFormatter,
        comparator: (vA, vB) => stringComparator(vA.join(','), vB.join(',')),
      },
    ],
    [t]
  )

  return (
    <div
      className={classNames('ag-theme-alpine', styles.table, props.className)}
    >
      <AgGridReact
        rowData={props.companies}
        defaultColDef={{ flex: 1, sortable: true, resizable: true }}
        overlayNoRowsTemplate={`<span>${t('Pages.PersonalizedReport.thereAreNoResultsToDisplay')}</span>`}
        columnDefs={columnDefs}
      />
    </div>
  )
}

export default CompaniesTable
