import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ESGPieChart, {
  Props as ESGPieChartProps,
} from '../../../../components/ESGPieChart'
import ScoreHistoryChart, {
  Props as ScoreHistoryChartProps,
} from '../../../../components/ScoreHistoryChart'
import { useWatchLoading } from '../../../../contexts/Loading'
import { formatRanking } from '../../utils'
import ScoreCards, { ScoreCardData } from '../ScoreCards'
import SubsectionVsPeersChart, {
  Props as SubsectionVsPeersChartProps,
} from '../SubsectionVsPeersChart'

interface Props {
  loading: boolean
  company: Company
  score: number
  ranking: number[]
  peerAverage: number
  esgPieChartProps: ESGPieChartProps | null
  scoreHistoryChartProps: ScoreHistoryChartProps | null
  subsectionVsPeersChartProps: SubsectionVsPeersChartProps | null
}

const Overview: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  useWatchLoading(props.loading)

  const cards: ScoreCardData[] | null = useMemo(
    () =>
      !props.loading
        ? [
            {
              color: 'primary',
              title: t('Pages.CompanyReport.score'),
              value: props.score.toFixed(0),
              className: 'w-24 p-1',
            },
            {
              color: 'secondary',
              title: t('Pages.CompanyReport.ranking'),
              value: formatRanking(props.ranking[0], props.ranking[1]),
              className: 'w-24 p-1',
            },
            {
              color: 'secondary',
              title: t('Pages.CompanyReport.peersAverage'),
              value: props.peerAverage.toFixed(0),
              className: 'w-24 p-1',
            },
          ]
        : null,
    [props.loading, props.score, props.ranking, props.peerAverage, t]
  )

  return props.loading ? null : (
    <div className="font-work-san px-4 pb-20">
      <div className="text-right text-3xl font-semibold">
        {t('Commons.overview')}
      </div>
      {props.company.desc && (
        <div className="mt-4 mb-8 text-right text-sm">{props.company.desc}</div>
      )}
      {cards && <ScoreCards cards={cards} />}
      <div className="mt-20">
        <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-4">
          <ESGPieChart
            className="mx-auto w-full md:col-span-2"
            {...props.esgPieChartProps!}
          />
          <ScoreHistoryChart {...props.scoreHistoryChartProps!} />
          <SubsectionVsPeersChart {...props.subsectionVsPeersChartProps!} />
        </div>
      </div>
    </div>
  )
}

export default Overview
