import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'
import LinkGrid from '../../../../components/LinkGrid'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import { getReferenceFormLinks } from '../../utils/remuneration'
import RemunerationCompanySelector from '../RemunerationCompanySelector'
import RemunerationDataCorrector from '../RemunerationDataCorrector'
import RemunerationLoadModal from '../RemunerationLoadModal'
import RemunerationReport from '../RemunerationReport'
import RemunerationSaveModal from '../RemunerationSaveModal'

interface Props {
  loading?: boolean
  companiesData: RemunerationData
  originalCompaniesData: RemunerationData
  consolidatedSummedData: RemunerationData
  estimatedSummedData: RemunerationData
  companiesList: RemunerationCompany[]
  summedCompanies: RemunerationCompany[]
  peers: RemunerationCompany[]
  includeIncompleteSum: boolean
  customEstimates: Record<string, RemunerationEstimates>
  savedReports: Pick<UserSavedRemunerationSumReport, 'id' | 'name'>[]
  lastReportName: string
  onActiveCompaniesChange?: (
    summedCompanies: RemunerationCompany[],
    peers: RemunerationCompany[]
  ) => any
  onCompaniesDataChange?: (data: RemunerationData) => any
  onIncludeIncompleteSumChange?: (value: boolean) => any
  onCustomEstimatesChange?: (
    estimates: Record<string, RemunerationEstimates>
  ) => any
  onSaveReport?: (name: string) => Promise<any>
  onLoadReport?: (id: string) => Promise<any>
  onRenameReport?: (id: string, name: string) => Promise<any>
  onDeleteReport?: (id: string) => Promise<any>
}

const RemunerationSum: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [summedCompanies, setSummedCompanies] = useState<RemunerationCompany[]>(
    []
  )
  const [peers, setPeers] = useState<RemunerationCompany[]>([])
  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false)
  const [loadModalOpen, setLoadModalOpen] = useState<boolean>(false)

  useEffect(() => {
    setSummedCompanies(props.summedCompanies)
    setPeers(props.peers)
  }, [props.summedCompanies, props.peers])

  const isBlankState = useMemo(
    () => Object.keys(props.companiesData).length === 0,
    [props.companiesData]
  )

  const referenceFormLinks = useMemo(
    () => getReferenceFormLinks(props.companiesData),
    [props.companiesData]
  )

  const savedReportNames = useMemo(
    () => props.savedReports.map((report) => report.name),
    [props.savedReports]
  )

  return (
    <div className="pb-20 pl-10 pr-4 font-work-sans">
      <div className="relative">
        <LoadingOverlay
          className="absolute inset-0 flex items-center justify-center bg-opacity-70"
          type="local"
          show={props.loading}
        />
        <div className="text-3xl font-semibold">
          {t('Pages.ThemesReport.remunaration')}
        </div>
        <RemunerationCompanySelector
          className="mt-4"
          label={`${t('Commons.companies')}:`}
          activeCompanies={summedCompanies}
          allCompanies={props.companiesList}
          placeholder={t('Pages.ThemesReport.selectTheCompaniesToBeAdded')}
          onActiveCompaniesChange={setSummedCompanies}
        />
        <RemunerationCompanySelector
          className="mt-4"
          label="Peers:"
          activeCompanies={peers}
          allCompanies={props.companiesList}
          placeholder={t('Pages.ThemesReport.selectThePeers')}
          onActiveCompaniesChange={setPeers}
        />
        <div className="mt-8 flex justify-end gap-4">
          <Button
            className="h-8 w-28"
            variant="primary"
            disabled={isBlankState}
            onClick={() => setSaveModalOpen(true)}
          >
            {t('Commons.save')}
          </Button>
          <Button
            className="h-8 w-28"
            variant="secondary"
            onClick={() => setLoadModalOpen(true)}
          >
            {t('Commons.load')}
          </Button>
          <Button
            className="h-8 w-28"
            variant="primary"
            onClick={() =>
              props.onActiveCompaniesChange?.(summedCompanies, peers)
            }
          >
            {t('Commons.analyse')}
          </Button>
        </div>
      </div>
      <RemunerationSaveModal
        open={saveModalOpen}
        onClose={() => setSaveModalOpen(false)}
        onSave={props.onSaveReport}
        savedReportNames={savedReportNames}
        lastReportName={props.lastReportName}
        closeButton
        dismissable
      />
      <RemunerationLoadModal
        open={loadModalOpen}
        onClose={() => setLoadModalOpen(false)}
        onLoad={props.onLoadReport}
        onRename={props.onRenameReport}
        onDelete={props.onDeleteReport}
        savedReports={props.savedReports}
        closeButton
        dismissable
      />
      {!isBlankState && (
        <>
          <LinkGrid className="mt-8" links={referenceFormLinks} />
          <div className="mt-4">
            <label className="flex cursor-pointer select-none items-center gap-x-2">
              <input
                type="checkbox"
                checked={props.includeIncompleteSum}
                onChange={(event) =>
                  props.onIncludeIncompleteSumChange?.(event.target.checked)
                }
              />
              <span>
                {t('Pages.ThemesReport.includeYearsWithIncompleteDataInTheSum')}
              </span>
            </label>
          </div>
          <RemunerationDataCorrector
            className="mt-4"
            data={props.companiesData}
            originalData={props.originalCompaniesData}
            onDataChange={props.onCompaniesDataChange}
          />
          <RemunerationReport
            className="mt-4"
            consolidatedData={props.consolidatedSummedData}
            estimatedData={props.estimatedSummedData}
            customEstimates={props.customEstimates}
            onCustomEstimatesChange={props.onCustomEstimatesChange}
          />
        </>
      )}
    </div>
  )
}

export default RemunerationSum
