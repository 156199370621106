import { useTranslation } from 'react-i18next'

import { useWatchLoading } from '../../../../contexts/Loading'
import type { QualiIndicator } from '../QualiIndicatorTable'
import QualiIndicatorTable from '../QualiIndicatorTable'

interface Props {
  loading: boolean
  companyName: string
  topics: {
    name: string
    indicators: QualiIndicator[]
  }[]
  className?: string
  isSingleTopic?: boolean
}

const ESGSubsectionQuali: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  useWatchLoading(props.loading)

  return props.loading ? null : (
    <div className={props.className}>
      <div className="font-bold">{t('Commons.qualitative')}</div>
      <QualiIndicatorTable
        className="mt-10"
        companyName={props.companyName}
        topics={props.topics}
        isSingleTopic={props.isSingleTopic}
      />
    </div>
  )
}

export default ESGSubsectionQuali
