import classNames from 'classnames'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import IndicatorDetails from './IndicatorDetails'

interface Props {
  className?: string
  companyName: string
  isSingleTopic?: boolean
  topics: {
    name: string
    indicators: QualiIndicator[]
  }[]
}

export interface QualiIndicator {
  title: string
  status: 'green' | 'yellow' | 'red'
  reason: string
}

const QualiIndicatorTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [activeTopic, setActiveTopic] = useState<string | null>(null)
  const [activeIndicator, setActiveIndicator] = useState<QualiIndicator | null>(
    null
  )

  const handleIndicatorClick = (topic: string, indicator: QualiIndicator) => {
    setActiveTopic(topic)
    setActiveIndicator(indicator)
  }

  useEffect(() => {
    const handleKeypress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setActiveIndicator(null)
      }
    }

    document.addEventListener('keydown', handleKeypress)

    return () => document.removeEventListener('keydown', handleKeypress)
  }, [])

  return (
    <div className="w-full overflow-x-auto">
      <table
        className={classNames(
          'min-w-full table-auto border-collapse border border-gray-300',
          props.className
        )}
      >
        <thead>
          <tr className="border-b-2 border-gray-300">
            {!props.isSingleTopic && (
              <th className="border-r border-gray-300 p-2 text-center font-bold">
                {t('Pages.CompanyReport.topics')}
              </th>
            )}
            <th className="border-r border-gray-300 p-2 text-center font-bold">
              {t('Pages.CompanyReport.criteria')}
            </th>
            <th className="border-gray-300 p-2 text-center font-bold">
              {t('Pages.CompanyReport.answers')}
            </th>
          </tr>
        </thead>
        <tbody>
          {props.topics.map((topic) => (
            <Fragment key={topic.name}>
              {topic.indicators.map((indicator, index) => (
                <tr key={indicator.title} className="border-b border-gray-300">
                  {!props.isSingleTopic && index === 0 && (
                    <td
                      className="border-r border-gray-300 p-2 text-center font-bold"
                      rowSpan={topic.indicators.length}
                    >
                      {topic.name}
                    </td>
                  )}
                  <td
                    className="cursor-pointer border-r border-gray-300 p-2 hover:bg-gray-100"
                    onClick={() => handleIndicatorClick(topic.name, indicator)}
                  >
                    {indicator.title}
                  </td>
                  <td
                    className="cursor-pointer border-gray-300 p-2 text-center"
                    onClick={() => handleIndicatorClick(topic.name, indicator)}
                  >
                    <div
                      className={classNames('h-6 w-6 rounded-full mx-auto', {
                        'bg-jgp-success-main': indicator.status === 'green',
                        'bg-jgp-warning-main': indicator.status === 'yellow',
                        'bg-jgp-error-main': indicator.status === 'red',
                      })}
                    />
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </table>

      {activeIndicator && (
        <div
          className="fixed inset-0 z-10 flex items-center justify-center p-2"
          onClick={() => setActiveIndicator(null)}
        >
          <div className="flex max-w-screen-lg flex-grow justify-center pl-64">
            <IndicatorDetails
              className="max-w-2xl flex-grow"
              companyName={props.companyName}
              topic={activeTopic!}
              indicator={activeIndicator.title}
              reason={activeIndicator.reason}
              onClose={() => setActiveIndicator(null)}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default QualiIndicatorTable
