import React from 'react'

interface Props {
  language: Language
  toggleLanguage: () => void
}

const LanguageSelection: React.FC<Props> = (props) => {
  return (
    <div className="flex items-center space-x-1 py-1">
      <span className="text-[10px] font-bold">PT</span>
      <label className="relative inline-flex cursor-pointer items-center">
        <input
          type="checkbox"
          checked={props.language === 'en'}
          onChange={props.toggleLanguage}
          className="peer sr-only"
        />
        <div className="h-3 w-6 rounded-full bg-gray-200 transition duration-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-jgp-secondary-light dark:bg-gray-600"></div>
        <div className="absolute left-0.5 top-0.5 h-2 w-2 transform rounded-full bg-white transition-transform duration-200 peer-checked:translate-x-3"></div>
      </label>
      <span className="text-[10px] font-bold">EN</span>
    </div>
  )
}

export default LanguageSelection
