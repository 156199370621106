import classNames from 'classnames'
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import Button from '../../../../components/Button'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  XIcon,
} from '../../../../components/icons'
import IntersectionObserver from '../../../../components/IntersectionObserver'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import Select from '../../../../components/Select'
import Spinner from '../../../../components/Spinner'
import type {
  Controversy,
  SearchArgs,
  SearchItem,
} from '../../clients/controversies'
import CreateCompanyModal from '../CreateCompanyModal'
import SearchResults from './SearchResults'

interface Props {
  loading: boolean
  companies: Pick<Company, 'id' | 'name'>[]
  searchResults: SearchItem[] | null
  controversies: Record<string, boolean>
  onSearch?: (companyId: string, args: SearchArgs) => Promise<any>
  onNextPage?: () => Promise<any>
  onInsertControversy: (args: {
    companyId: string
    controversy: Controversy
  }) => Promise<any>
  onRemoveControversy: (args: {
    companyId: string
    controversy: { data: Pick<Controversy['data'], 'link'> }
  }) => Promise<any>
  onCreatedCompany: (name: string) => Promise<Pick<Company, 'id' | 'name'>>
}

type CompanyOption = SelectOption<Pick<Company, 'id' | 'name'>>
type DateRangeType = 'd' | 'w' | 'm' | 'y'
type DateRangeTypeOption = SelectOption<DateRangeType>

interface SearchParam {
  id: string
  terms: string
}

interface SearchQuery {
  id: string
  params: SearchParam[]
}

const defaultCompanyName = 'Nome da empresa'

const dateRangeTypeOptions: DateRangeTypeOption[] = [
  { label: 'dias', value: 'd' },
  { label: 'semanas', value: 'w' },
  { label: 'meses', value: 'm' },
  { label: 'anos', value: 'y' },
]

const defaultSearchParameters = {
  environmental: [
    [
      'ambiental, natureza, meio ambiente, reserva, vegetação, floresta, biodiversidade, animais, fauna',
      'queimada, incêndio, deflorestamento, desmatamento, grilagem, vegetação, licença, violação ambiental, danos, multa',
    ],
    ['água, rio, efluente', 'vazamento, lixo, poluição, desastre, multa'],
  ],
  social: [
    [
      'social, comunidade, população, vizinhos, sociedade, indígenas, quilombolas, cliente',
      'manifestação, racismo, preconceito, violência, vazamento de dados, hacker, cyber ataque, homofobia, processo',
    ],
    [
      'trabalhador, funcionário, colaborador, terceirizado',
      'morte, morre, acidente, doença, trabalho escravo, trabalho infantil, condições de trabalho, exaustiva, assédio, racismo, homofobia, gordofobia',
    ],
  ],
  governance: [
    [
      'governança, corrupção, fraude, administradores, MP, Ministério Público, condenada, acusada, processo, multa, diretor, conselho de administração, TAC, Termo de Ajustamento de Conduta, escândalo',
    ],
    [
      'investigação, CPI, acionistas, minoritários, dívida, recuperação judicial, embargo, bloqueio',
    ],
  ],
}

const SearchQueryConfig = (props: {
  searchQuery: SearchQuery
  onChange: (paramId: string, terms: string) => any
  onAddParam: () => any
  onRemoveParam: (paramId: string) => any
  onRemoveQuery: () => any
  allowRemove?: boolean
  title: string
}) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-x-2">
        <span className="text-xs">{props.title}</span>
        <button
          className={props.allowRemove ? '' : 'hidden'}
          onClick={props.onRemoveQuery}
        >
          <XIcon className="h-4 w-4 text-jgp-error-light" />
        </button>
      </div>
      <div className="ml-2 grid grid-cols-[max-content_auto_min-content] items-center gap-2">
        {props.searchQuery.params.map((param, idx) => (
          <Fragment key={param.id}>
            <span className="text-xs">Parâmetro {idx + 1}</span>
            <textarea
              className="rounded-none border border-[rgb(204,204,204)] py-2 px-2 text-sm focus:border-opacity-0 focus:outline-none focus:ring-2 focus:ring-jgp-success-dark"
              defaultValue={param.terms}
              onBlur={(event) => props.onChange(param.id, event.target.value)}
            />
            <button
              className={props.searchQuery.params.length > 1 ? '' : 'invisible'}
              onClick={() => props.onRemoveParam(param.id)}
            >
              <XIcon className="h-4 w-4 text-jgp-error-light" />
            </button>
          </Fragment>
        ))}
      </div>
      <button
        className="mt-2 self-end text-sm text-jgp-success-main"
        onClick={props.onAddParam}
      >
        Adicionar parâmetro
      </button>
    </div>
  )
}

const ControversiesSearch: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [companyOption, setCompanyOption] = useState<CompanyOption | null>(null)
  const [query, setQuery] = useState<string>('')
  const [dateRangeCount, setDateRangeCount] = useState<number>(3)
  const [dateRangeTypeOption, setDateRangeTypeOption] =
    useState<DateRangeTypeOption>(dateRangeTypeOptions[2])
  const [loadingNextPage, setLoadingNextPage] = useState<boolean>(false)
  const [newCompanyName, setNewCompanyName] = useState<string>('')
  const [customizeSearchExpanded, setCustomizeSearchExpanded] =
    useState<boolean>(false)
  const [searchQueries, setSearchQueries] = useState<SearchQuery[]>([])
  const [searchTypeOption, setSearchTypeOption] = useState<SelectOption<
    'environmental' | 'social' | 'governance'
  > | null>(null)

  const companySelectRef = useRef<any>(null)
  const queryTextAreaRef = useRef<HTMLTextAreaElement>(null)

  const companyOptions = useMemo(
    () =>
      props.companies.map((company) => ({
        label: company.name,
        value: company,
      })),
    [props.companies]
  )

  const updateQuery = useCallback(() => {
    const queries = searchQueries.map((q) =>
      q.params
        .map((p) => {
          if (!p.terms) {
            return null
          }

          const terms = p.terms.split(',').map((t) => `"${t.trim()}"`)

          return [
            terms.length > 1 && '(',
            terms.join(' OR '),
            terms.length > 1 && ')',
          ]
            .filter(Boolean)
            .join('')
        })
        .filter(Boolean)
        .join(' AND ')
    )

    const result =
      queries.length === 1
        ? queries[0]
        : queries.map((q) => `(${q})`).join(' OR ')

    setQuery(result)
  }, [searchQueries])

  useEffect(() => {
    updateQuery()
  }, [updateQuery, companyOption])

  const handleSearch = async () => {
    if (!companyOption) {
      companySelectRef.current?.focus()
      return
    }

    if (!query) {
      queryTextAreaRef.current?.focus()
      return
    }

    if (!props.onSearch || !searchTypeOption) {
      return
    }

    return props.onSearch(companyOption.value.id, {
      q: query,
      start: 1,
      dateRestrict: `${dateRangeTypeOption.value}${dateRangeCount}`,
    })
  }

  const handleVisible = () => {
    if (loadingNextPage || !props.onNextPage) {
      return
    }

    setLoadingNextPage(true)

    return props.onNextPage?.().finally(() => setLoadingNextPage(false))
  }

  const handleCreatedCompany = (name: string) => {
    props
      .onCreatedCompany?.(name)
      .then((company) =>
        setCompanyOption({ label: company.name, value: company })
      )
  }

  return (
    <div className="relative pb-20 pl-10 pr-4 font-work-sans">
      <LoadingOverlay
        className="absolute inset-0 flex justify-center bg-opacity-70"
        type="overlay"
        show={props.loading}
      />
      <div className="text-3xl font-semibold">
        {t('Pages.ThemesReport.searchForControversies')}
      </div>
      <div className="mt-4">
        <span className="text-xs">{t('Commons.company')}</span>
        <Select<CompanyOption>
          creatable
          innerRef={companySelectRef}
          color="dark"
          icon={null}
          placeholder={t('Pages.ThemesReport.selectCompany')}
          options={companyOptions}
          value={companyOption}
          onChange={(option) => {
            setCompanyOption(option)
            setSearchQueries((cur) =>
              cur.map((q) => ({
                ...q,
                params: q.params.map((p, idx) =>
                  idx === 0
                    ? { id: uuid(), terms: option?.label ?? defaultCompanyName }
                    : p
                ),
              }))
            )
          }}
          formatCreateLabel={() => t('Pages.ThemesReport.registerNewCompany')}
          onCreateOption={setNewCompanyName}
        />
        {newCompanyName && (
          <CreateCompanyModal
            name={newCompanyName}
            companies={props.companies}
            onClose={() => setNewCompanyName('')}
            onCreated={handleCreatedCompany}
          />
        )}
      </div>
      <div className="flex justify-end">
        <button
          className="mt-2 text-sm text-jgp-success-main"
          onClick={() => setNewCompanyName('Nova empresa')}
        >
          {t('Pages.ThemesReport.registerNewCompany')}
        </button>
      </div>
      <div>
        <span className="text-xs"> {t('Pages.ThemesReport.searchType')}</span>
        <Select<{
          label: string
          value: 'environmental' | 'social' | 'governance'
        }>
          className="text-sm"
          color="dark"
          icon={null}
          placeholder={t('Pages.ThemesReport.selectSearch')}
          value={searchTypeOption}
          options={[
            { label: 'Environmental', value: 'environmental' },
            { label: 'Social', value: 'social' },
            { label: 'Governance', value: 'governance' },
          ]}
          onChange={(option) => {
            if (!option) {
              return
            }
            setSearchTypeOption(option)
            setSearchQueries(
              defaultSearchParameters[option.value].map((params) => ({
                id: uuid(),
                params: [
                  {
                    id: uuid(),
                    terms: companyOption?.label || defaultCompanyName,
                  },
                  ...params.map((terms) => ({
                    id: uuid(),
                    terms,
                  })),
                ],
              }))
            )
          }}
        />
      </div>
      <div className="mt-4">
        <span className="text-xs">
          {t('Pages.ThemesReport.searchInterval')}
        </span>
        <div className="flex items-center gap-x-2">
          <span className="text-xs">{t('Pages.ThemesReport.latest')}</span>
          <input
            className="h-[36px] w-12 rounded-none border border-[rgb(204,204,204)] px-2 text-sm focus:border-opacity-0 focus:outline-none focus:ring-2 focus:ring-jgp-success-dark"
            type="number"
            min={1}
            value={dateRangeCount}
            onChange={(event) => setDateRangeCount(Number(event.target.value))}
          />
          <Select<DateRangeTypeOption>
            className="w-32 text-sm"
            color="dark"
            icon={null}
            options={dateRangeTypeOptions}
            value={dateRangeTypeOption}
            onChange={(option) => option && setDateRangeTypeOption(option)}
          />
        </div>
      </div>
      <button
        className="mt-4 flex items-center gap-x-1 text-xs"
        onClick={() => setCustomizeSearchExpanded((cur) => !cur)}
      >
        {t('Commons.search')}
        {customizeSearchExpanded ? (
          <ChevronDownIcon className="h-4 w-4" />
        ) : (
          <ChevronRightIcon className="h-4 w-4" />
        )}
      </button>
      <div className={classNames('mt-2', !customizeSearchExpanded && 'hidden')}>
        <span className="text-xs">
          {t('Pages.ThemesReport.searchParameters')}
        </span>
        <div className="ml-2 flex flex-col gap-y-2">
          {searchQueries.map((searchQuery, idx) => (
            <SearchQueryConfig
              key={searchQuery.id}
              title={`${t('Pages.ThemesReport.searchParameters')} ${idx + 1}`}
              searchQuery={searchQuery}
              allowRemove={searchQueries.length > 1}
              onRemoveQuery={() =>
                setSearchQueries((cur) =>
                  cur.filter((q) => q.id !== searchQuery.id)
                )
              }
              onChange={(paramId, terms) =>
                setSearchQueries((cur) =>
                  cur.map((sq) =>
                    sq.id === searchQuery.id
                      ? {
                          ...sq,
                          params: sq.params.map((p) =>
                            p.id === paramId ? { ...p, terms } : p
                          ),
                        }
                      : sq
                  )
                )
              }
              onAddParam={() =>
                setSearchQueries((cur) =>
                  cur.map((sq) =>
                    sq.id === searchQuery.id
                      ? {
                          ...sq,
                          params: sq.params.concat({ id: uuid(), terms: '' }),
                        }
                      : sq
                  )
                )
              }
              onRemoveParam={(paramId) =>
                setSearchQueries((cur) =>
                  cur.map((sq) =>
                    sq.id === searchQuery.id
                      ? {
                          ...sq,
                          params: sq.params.filter((p) => p.id !== paramId),
                        }
                      : sq
                  )
                )
              }
            />
          ))}
          <button
            className="self-end text-sm text-jgp-success-main"
            onClick={() =>
              setSearchQueries((cur) =>
                cur.concat({
                  id: uuid(),
                  params: [
                    {
                      id: uuid(),
                      terms: companyOption?.label || defaultCompanyName,
                    },
                    {
                      id: uuid(),
                      terms: '',
                    },
                  ],
                })
              )
            }
          >
            {t('Pages.ThemesReport.addSearch')}
          </button>
        </div>
        <span className="text-xs">{t('Pages.ThemesReport.searchText')}</span>
        <textarea
          ref={queryTextAreaRef}
          className="w-full rounded-none border border-[rgb(204,204,204)] px-2 text-sm focus:border-opacity-0 focus:outline-none focus:ring-2 focus:ring-jgp-success-dark"
          rows={3}
          value={query}
          onChange={(event) => setQuery(event.target.value)}
        />
      </div>
      <div className="mt-8 flex justify-end">
        <Button className="h-8 w-28" variant="primary" onClick={handleSearch}>
          {t('Commons.searchh')}
        </Button>
      </div>
      {companyOption && props.searchResults && searchTypeOption && (
        <SearchResults
          type={searchTypeOption.value}
          hits={props.searchResults}
          company={companyOption.value}
          controversies={props.controversies}
          onInsertControversy={props.onInsertControversy}
          onRemoveControversy={props.onRemoveControversy}
        />
      )}
      {props.searchResults?.length === 0 && (
        <p> {t('Pages.ThemesReport.noResultsFound')}</p>
      )}
      <IntersectionObserver onVisible={handleVisible} />
      {loadingNextPage && (
        <div className="mt-8 flex justify-center">
          <Spinner className="text-jgp-success-main" size={32} />
        </div>
      )}
    </div>
  )
}

export default ControversiesSearch
