import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useWatchLoading } from '../../../../contexts/Loading'
import ESGSubsectionQualiContainer from '../../containers/ESGSubsectionQuali'
import ESGSubsectionQuantContainer from '../../containers/ESGSubsectionQuant'
import { formatRanking } from '../../utils'
import ScoreCards, { ScoreCardData } from '../ScoreCards'

interface Props {
  loading: boolean
  section: ESGSection
  subsection: string
  score: number | null
  ranking: number[]
  peerAverageScore: number | null
  weight: number | null
  hasQuant: boolean
  hasQuali: boolean
  className?: string
}

const ESGSubsection: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  useWatchLoading(props.loading)

  const cards: ScoreCardData[] | null = useMemo(
    () =>
      !props.loading
        ? [
            {
              color: 'primary',
              title: t('Pages.CompanyReport.score'),
              value: props.score!.toFixed(0),
              className: 'w-24 p-1',
            },
            {
              color: 'secondary',
              title: t('Pages.CompanyReport.ranking'),
              value: formatRanking(props.ranking[0], props.ranking[1]),
              className: 'w-24 p-1',
            },
            {
              color: 'secondary',
              title: t('Commons.weight'),
              value: (100 * props.weight!).toFixed(0) + '%',
              className: 'w-24 p-1',
            },
            {
              color: 'secondary',
              title: t('Pages.CompanyReport.peersAverage'),
              value: props.peerAverageScore!.toFixed(0),
              className: 'w-24 p-1',
            },
          ]
        : null,
    [
      props.loading,
      props.score,
      props.ranking,
      props.weight,
      props.peerAverageScore,
      t,
    ]
  )

  return props.loading ? null : (
    <div className={classNames('font-work-sans', props.className)}>
      <div className="text-xl font-semibold">{props.subsection}</div>
      {props.hasQuali && cards && <ScoreCards cards={cards} />}
      {props.hasQuali && (
        <ESGSubsectionQualiContainer
          className="mt-10"
          section={props.section}
          subsection={props.subsection}
        />
      )}
      {props.hasQuant && (
        <ESGSubsectionQuantContainer
          className="mt-10"
          section={props.section}
          subsection={props.subsection}
        />
      )}
    </div>
  )
}

export default ESGSubsection
