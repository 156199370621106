import classNames from 'classnames'
import hash from 'object-hash'
import { FunctionComponent } from 'react'

import RemunerationColumnChart, {
  ColumnChartConfig,
} from '../RemunerationColumnChart'
import RemunerationTimeSeriesChart, {
  TimeSeriesChartConfig,
} from '../RemunerationTimeSeriesChart'

interface Props {
  className?: string
  charts: Chart[]
}

type Chart = TimeSeriesChartConfig | ColumnChartConfig

const RemunerationReportChartSection: FunctionComponent<Props> = (props) => {
  return (
    <div
      className={classNames(
        'grid grid-cols-2 lg:gap-x-4 gap-y-16',
        props.className
      )}
    >
      {props.charts.map((chart) =>
        chart.type === 'timeseries' ? (
          <RemunerationTimeSeriesChart key={hash(chart)} config={chart} />
        ) : chart.type === 'breakdown' ? (
          <RemunerationColumnChart key={hash(chart)} config={chart} />
        ) : null
      )}
    </div>
  )
}

export default RemunerationReportChartSection
