import React from 'react'
import { useTranslation } from 'react-i18next'

import Logo from '../Logo'

const UpperFooter: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-center bg-jgp-success-main px-4 pt-6 pb-8 font-work-sans text-white md:pt-8 md:pb-12">
      <div className="w-full max-w-screen-xl">
        <div className="flex flex-col items-start justify-between md:flex-row">
          <Logo className="mb-10 h-11 md:mb-0" />
          <div className="mb-6 flex flex-col md:mb-0">
            <div className="mb-4 text-lg md:text-xl">
              {t('Components.Footer.the')}
              <span className="font-bold"> JGP</span>
            </div>
            <a
              className="h-8 text-base md:text-sm"
              href="https://www.jgp.com.br/quem-somos"
            >
              {t('Components.Footer.whoWeAre')}
            </a>
            <a
              className="h-8 text-base md:text-sm"
              href="https://www.jgp.com.br/quem-somos#filosofia"
            >
              {t('Components.Footer.investmentPhilosophy')}
            </a>
            <a
              className="h-8 text-base md:text-sm"
              href="https://bit.ly/jgp-esg-lp-f%E2%80%8B"
            >
              {t('Components.Footer.esg')}
            </a>
            <a
              className="h-8 text-base md:text-sm"
              href="https://www.jgp.com.br/informacoes-regulatorias"
            >
              {t('Components.Footer.regulatoryInformation')}
            </a>
          </div>
          <div className="mb-6 flex flex-col md:mb-0">
            <div className="mb-4 text-lg md:text-xl">
              <span className="font-bold">Asset </span> Management
            </div>
            <a
              className="h-8 text-base md:text-sm"
              href="https://www.jgp.com.br/asset-management"
            >
              {t('Components.Footer.allFunds')}
            </a>
            <a
              className="h-8 text-base md:text-sm"
              href="https://www.jgp.com.br/processo-investimento-asset"
            >
              {t('Components.Footer.investmentProcess')}
            </a>
            <a
              className="h-8 text-base md:text-sm"
              href="https://www.jgp.com.br/asset-management"
            >
              {t('Components.Footer.howToInvest')}
            </a>
            <a
              className="h-8 text-base md:text-sm"
              href="https://www.jgp.com.br/relatorio-gestao"
            >
              {t('Components.Footer.managementReports')}
            </a>
            <a
              className="h-8 text-base md:text-sm"
              href="https://www.jgp.com.br/cotas-rentabilidades"
            >
              {t('Components.Footer.sharesAndReturns')}
            </a>
          </div>
          <div className="flex flex-col">
            <div className="mb-4 text-lg md:text-xl">
              <span className="font-bold">Wealth </span> Management
            </div>
            <a
              className="h-8 text-base md:text-sm"
              href="https://www.jgp.com.br/wealth-management"
            >
              {t('Components.Footer.aboutUs')}
            </a>
            <a
              className="h-8 text-base md:text-sm"
              href="https://www.jgp.com.br/wealth-management"
            >
              {t('Components.Footer.services')}
            </a>
            <a
              className="h-8 text-base md:text-sm"
              href="https://www.jgp.com.br/processo-investimento-wealth"
            >
              {t('Components.Footer.investmentProcess')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpperFooter
