import 'ag-grid-enterprise'

import type { ColDef, ValueFormatterParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './DataTable.module.css'

interface Props {
  data: RowData[]
  companyName: string
  className?: string
}

export interface RowData {
  categoryPath: string[]
  companyScore: number
  peerAverage: number
  weight: number
  isIndicator?: boolean
}

const valueFormatter = (params: ValueFormatterParams) => params.value.toFixed(0)

const percentFormatter = (params: ValueFormatterParams) => {
  const value = params.value
  return `${(100 * value).toFixed(0)}%`
}

const DataTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'companyScore',
        maxWidth: 135,
        headerName: props.companyName,
        valueFormatter,
      },
      {
        field: 'peerAverage',
        maxWidth: 135,
        headerName: t('Pages.CompanyReport.peersAverage'),
        valueFormatter,
      },
      {
        field: 'weight',
        maxWidth: 100,
        headerName: t('Commons.Weight'),
        valueFormatter: percentFormatter,
      },
    ],
    [props.companyName, t]
  )

  return (
    <div
      className={classNames(
        'ag-theme-alpine',
        props.className,
        styles.container
      )}
    >
      <AgGridReact
        rowData={props.data}
        getDataPath={(row: RowData) => row.categoryPath}
        autoGroupColumnDef={{
          headerName: t('Pages.CompanyReport.category'),
          flex: 1,
          cellRendererParams: { suppressCount: true },
          cellClassRules: {
            'whitespace-normal leading-6': (params: any) =>
              params.data.isIndicator,
          },
          autoHeight: true,
        }}
        treeData
        columnDefs={columnDefs}
      />
    </div>
  )
}

export default DataTable
