import type { PermissionName } from '@jgp-er-dev/permissions'
import React from 'react'

import LinksList from './LinksList'

interface Props {
  checkPermissions: (permissions: PermissionName[]) => boolean
}

const NavigationLinks: React.FC<Props> = (props) => {
  return (
    <div className="ml-16 hidden h-full md:flex">
      <LinksList checkPermissions={props.checkPermissions} />
    </div>
  )
}

export default NavigationLinks
