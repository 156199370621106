import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import NotFound from '../../../../components/NotFound'
import ReportHeader from '../../../../components/ReportHeader'
import ReportSidebar from '../../../../components/ReportSidebar'
import type { Section } from '../../../../components/ReportSidebar/SidebarSection'
import SidebarModal from '../../../../components/SidebarModal'
import SidebarToggleButton from '../../../../components/SidebarToggleButton'
import {
  LoadingContextProvider,
  useWatchLoading,
} from '../../../../contexts/Loading'
import { Path } from '../../../../utils/constants'
import ControversiesContainer from '../../containers/Controversies'
import DataTableContainer from '../../containers/DataTable'
import ESGSectionContainer from '../../containers/ESGSection'
import OverviewContainer from '../../containers/Overview'
import ScoreTableContainer from '../../containers/ScoreTable'
import { useSectoralReport } from '../../contexts/SectoralReport'
import { useSectoralPath } from '../../utils/hooks'
import styles from './SectoralReport.module.css'

interface Props {
  loading: boolean
  sector: string | null
  sections: Section[]
  isFavorite: boolean
  isNotified: boolean
  onFavoriteChange?: (value: boolean) => Promise<void>
  onNotificationChange?: (value: boolean) => Promise<void>
  onDownload?: () => Promise<void>
  className?: string
}

const SectoralReport: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { sector, section } = useSectoralPath()
  const { availableYears, year, setYear } = useSectoralReport()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  useWatchLoading(props.loading)

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState)
  }

  if (!section) {
    return (
      <Redirect to={[Path.REPORTS_SECTORAL, sector, 'overview'].join('/')} />
    )
  }

  const renderBody = () => {
    switch (section) {
      case 'overview':
        return <OverviewContainer sector={props.sector!} />
      case 'environmental':
      case 'social':
      case 'governance':
        return <ESGSectionContainer key={section} section={section} />
      case 'controversies':
        return <ControversiesContainer />
      case 'scores':
        return <ScoreTableContainer className="flex-grow" />
      case 'data':
        return <DataTableContainer className="flex-grow" />
      default:
        return <NotFound />
    }
  }

  return props.loading ? null : (
    <>
      <ReportHeader
        className="flex-none"
        type="sector"
        name={props.sector!}
        isFavorite={props.isFavorite}
        isNotified={props.isNotified}
        onFavoriteChange={props.onFavoriteChange}
        onNotificationChange={props.onNotificationChange}
        onDownload={props.onDownload}
      />
      <div className="flex justify-end p-4 lg:hidden">
        <SidebarToggleButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
      </div>
      <div
        className={classNames('flex flex-grow justify-center', props.className)}
      >
        <div className="flex w-full max-w-screen-xl items-stretch">
          <div className="hidden lg:mt-4 lg:block lg:w-64">
            <ReportSidebar
              className="lg:mt-4"
              basePath={[Path.REPORTS_SECTORAL, sector].join('/')}
              sections={props.sections}
            />
          </div>
          <div className="mt-8 flex flex-grow flex-col">
            {year === null ? (
              <div>{t('Pages.SectoralReports.thereIsNoDataForThisSector')}</div>
            ) : (
              <>
                <div className="flex items-center justify-end px-4">
                  <span className="text-sm">{t('Commons.year')}:</span>
                  <select
                    className="ml-2 w-40 rounded-sm border"
                    value={year!}
                    onChange={(event) => setYear(parseInt(event.target.value))}
                  >
                    {availableYears.map((year) => (
                      <option key={year} value={year} label={year.toString()} />
                    ))}
                  </select>
                </div>
                <div
                  className={classNames(
                    'relative flex flex-col',
                    styles.loadingContainer
                  )}
                >
                  <LoadingContextProvider
                    className="absolute flex h-full w-full justify-center pt-48"
                    type="local"
                  >
                    {renderBody()}
                  </LoadingContextProvider>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <SidebarModal isOpen={isSidebarOpen} onClose={toggleSidebar}>
        <ReportSidebar
          basePath={[Path.REPORTS_SECTORAL, sector].join('/')}
          sections={props.sections}
          toggleSidebar={toggleSidebar}
        />
      </SidebarModal>
    </>
  )
}

export default SectoralReport
