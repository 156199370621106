import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CogIcon } from '../icons'

interface Props {
  className?: string
  onClick?: () => void
}

const ManageButton: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <button
      className={classNames(
        'flex items-center text-white focus:outline-none',
        props.className
      )}
      onClick={props.onClick}
    >
      <CogIcon className="mr-1.5 h-6 w-6" />
      {t('Common.manage')}
    </button>
  )
}

export default ManageButton
