import { useTranslation } from 'react-i18next'

import Modal from '../../../../components/Modal'
import Spinner from '../../../../components/Spinner'
import type { SearchItem } from '../../clients/controversies'

export interface Action {
  intent: 'insert' | 'remove'
  hit: SearchItem
}
interface Props {
  action: Action | null
  company: Pick<Company, 'id' | 'name'>
  isPending: boolean
  error?: string | null
  onClose: () => any
  onAction: (action: Action) => any
}

const ControversiesActionModal: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Modal open={!!props.action} onClose={props.onClose}>
      <div className="p-8">
        <h2 className="text-2xl">
          {props.action?.intent === 'insert'
            ? t('Pages.ThemesReport.markControversy')
            : t('Pages.ThemesReport.removeControversy')}
        </h2>
        <p className="mt-4">
          {props.action?.intent === 'insert'
            ? `${t('Pages.ThemesReport.markAsSeriousControversy')} `
            : `${t('Pages.ThemesReport.removeFromSeriousControversies')} `}
          <strong>{props.company.name}</strong>?
        </p>
        <div className="mt-4 ml-4 grid grid-cols-[min-content_auto] gap-x-2">
          <span>{t('Pages.ThemesReport.title')}:</span>
          <span>{props.action?.hit.title}</span>
          <span>{t('Pages.ThemesReport.link')}:</span>
          <a
            className="truncate text-jgp-success-main"
            href={props.action?.hit.link}
          >
            {props.action?.hit.link}
          </a>
        </div>
        {props.error && (
          <p className="text-jgp-props.error-main mt-4 text-sm">
            {props.error}
          </p>
        )}
        <div className="mt-4 flex justify-end gap-x-6">
          <button
            className="flex w-[4.5rem] items-center justify-center text-jgp-success-dark"
            onClick={() => props.action && props.onAction(props.action)}
            disabled={props.isPending}
          >
            {props.isPending ? <Spinner size={18} /> : 'Confirmar'}
          </button>
          <button
            className="text-jgp-danger-dark"
            onClick={props.onClose}
            disabled={props.isPending}
          >
            {t('Commons.cancel')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ControversiesActionModal
