import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const LowerFooter: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-center p-4 font-work-sans">
      <div className="flex w-full max-w-screen-xl flex-col justify-between md:flex-row md:items-center">
        <div className="flex w-full overflow-x-auto whitespace-nowrap py-3 text-sm md:w-auto md:justify-center md:py-0">
          <Link
            className="flex-shrink-0 border-r border-black pr-4 md:pr-8"
            to="#"
          >
            {t('Components.Footer.termsAndConditions')}
          </Link>
          <Link
            className="flex-shrink-0 border-r border-black px-4 md:px-8"
            to="#"
          >
            {t('Components.Footer.privacyPolicy')}
          </Link>
          <Link className="flex-shrink-0 px-4 md:px-8" to="#">
            FAQ
          </Link>
        </div>
        <div className="mt-4 text-xs text-jgp-secondary-dark md:mt-0 md:text-right">
          Copyright © 2021 JGP · {t('Components.Footer.allRightsReserved')}
        </div>
      </div>
    </div>
  )
}

export default LowerFooter
