import i18n from 'i18next'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface ILanguageContext {
  language: Language
  toggleLanguage: () => void
}

const LanguageContext = createContext<ILanguageContext>({} as ILanguageContext)

export const LanguageContextProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useState<Language>(() => {
    const storedLanguage = localStorage.getItem('language') as Language | null
    return storedLanguage || 'pt'
  })

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  const toggleLanguage = useCallback(() => {
    const newLanguage = language === 'pt' ? 'en' : 'pt'
    setLanguage(newLanguage)
    localStorage.setItem('language', newLanguage)
  }, [language])

  const value = useMemo(
    () => ({
      language,
      toggleLanguage,
    }),
    [language, toggleLanguage]
  )

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => {
  const context = useContext(LanguageContext)

  if (!context) {
    throw new Error('useLanguage must be used within a LanguageContextProvider')
  }

  return context
}
