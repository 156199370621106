import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'
import LinkGrid from '../../../../components/LinkGrid'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import { getReferenceFormLinks } from '../../utils/remuneration'
import RemunerationCompanySelector from '../RemunerationCompanySelector'
import RemunerationDataCorrector from '../RemunerationDataCorrector'
import RemunerationLoadModal from '../RemunerationLoadModal'
import RemunerationReport from '../RemunerationReport'
import RemunerationSaveModal from '../RemunerationSaveModal'

interface Props {
  className?: string
  loading?: boolean
  allCompanies: RemunerationCompany[]
  activeCompanies: RemunerationCompany[]
  companiesData: RemunerationData
  originalCompaniesData: RemunerationData
  customEstimates: Record<string, RemunerationEstimates>
  savedReports: Pick<UserSavedRemunerationReport, 'id' | 'name'>[]
  lastReportName: string
  onCustomEstimatesChange?: (
    estimates: Record<string, RemunerationEstimates>
  ) => any
  onCompaniesDataChange?: (data: RemunerationData) => any
  onActiveCompaniesChange?: (companies: RemunerationCompany[]) => any
  onSaveReport?: (name: string) => Promise<any>
  onLoadReport?: (id: string) => Promise<any>
  onRenameReport?: (id: string, name: string) => Promise<any>
  onDeleteReport?: (id: string) => Promise<any>
}

const Remuneration: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [activeCompanies, setActiveCompanies] = useState<RemunerationCompany[]>(
    props.activeCompanies
  )
  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false)
  const [loadModalOpen, setLoadModalOpen] = useState<boolean>(false)

  useEffect(() => {
    setActiveCompanies(props.activeCompanies)
  }, [props.activeCompanies])

  const isBlankState = Object.keys(props.companiesData).length === 0

  const referenceFormLinks = useMemo(
    () => getReferenceFormLinks(props.companiesData),
    [props.companiesData]
  )

  const savedReportNames = useMemo(
    () => props.savedReports.map((report) => report.name),
    [props.savedReports]
  )

  return (
    <div
      className={classNames(
        'relative pb-20 pl-10 pr-4 font-work-sans',
        props.className
      )}
    >
      <LoadingOverlay
        className="absolute inset-0 flex justify-center bg-opacity-70"
        type="local"
        show={props.loading}
      />
      <div className="text-3xl font-semibold">{t('Commons.remuneration')}</div>
      <RemunerationCompanySelector
        className="mt-4"
        label={`${t('Commons.companies')}:`}
        activeCompanies={activeCompanies}
        allCompanies={props.allCompanies}
        placeholder={t('Pages.CompanyReport.selectCompanies')}
        onActiveCompaniesChange={setActiveCompanies}
      />
      <div className="mt-8 flex justify-end gap-4">
        <Button
          className="h-8 w-28"
          variant="primary"
          disabled={isBlankState}
          onClick={() => setSaveModalOpen(true)}
        >
          {t('Commons.save')}
        </Button>
        <Button
          className="h-8 w-28"
          variant="secondary"
          onClick={() => setLoadModalOpen(true)}
        >
          {t('Commons.load')}
        </Button>
        <Button
          className="h-8 w-28"
          variant="primary"
          onClick={() => props.onActiveCompaniesChange?.(activeCompanies)}
        >
          {t('Commons.analyse')}
        </Button>
      </div>
      <RemunerationSaveModal
        open={saveModalOpen}
        onClose={() => setSaveModalOpen(false)}
        onSave={props.onSaveReport}
        savedReportNames={savedReportNames}
        lastReportName={props.lastReportName}
        closeButton
        dismissable
      />
      <RemunerationLoadModal
        open={loadModalOpen}
        onClose={() => setLoadModalOpen(false)}
        onLoad={props.onLoadReport}
        onRename={props.onRenameReport}
        onDelete={props.onDeleteReport}
        savedReports={props.savedReports}
        closeButton
        dismissable
      />
      {!isBlankState && (
        <>
          <LinkGrid className="mt-8" links={referenceFormLinks} />
          <RemunerationDataCorrector
            className="mt-4"
            data={props.companiesData}
            originalData={props.originalCompaniesData}
            onDataChange={props.onCompaniesDataChange}
          />
          <RemunerationReport
            className="mt-4"
            consolidatedData={props.companiesData}
            estimatedData={props.companiesData}
            customEstimates={props.customEstimates}
            onCustomEstimatesChange={props.onCustomEstimatesChange}
            onDataChange={props.onCompaniesDataChange}
          />
        </>
      )}
    </div>
  )
}

export default Remuneration
