import classNames from 'classnames'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import ColumnChart from '../../../../components/ColumnChart'
import { useWatchLoading } from '../../../../contexts/Loading'

interface Props {
  className?: string
  loading: boolean
  companyName: string
  chartProps: ChartProps[]
}

export interface ChartProps {
  indicatorColumnChartProps: {
    type: 'vertical'
    title: string
    unit: string
    data: {
      name: string
      value: number
    }[]
  }
  vsPeersColumnChartProps: {
    type: 'vertical'
    title: string
    unit: string
    data: {
      name: string
      value: number
    }[]
    average: number
  }
}

const DecarbonizationQuant: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  useWatchLoading(props.loading)

  return props.loading ? null : (
    <div
      className={classNames(
        'grid grid-cols-1 md:grid-cols-2 lg:gap-x-4 gap-y-16',
        props.className
      )}
    >
      {props.chartProps.length === 0 && (
        <div
          className={classNames(
            'flex items-center justify-center py-8 text-gray-400',
            props.className
          )}
        >
          {t('Commons.noDataToDisplay')}
        </div>
      )}
      {props.chartProps.map((indicator) => {
        return (
          <Fragment
            key={props.className + indicator.indicatorColumnChartProps.title}
          >
            {/* We add className to the key as a hack to remount the charts when the tab changes */}
            <ColumnChart {...indicator.indicatorColumnChartProps} />
            <ColumnChart {...indicator.vsPeersColumnChartProps} />
          </Fragment>
        )
      })}
    </div>
  )
}

export default DecarbonizationQuant
