import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import NotFound from '../../../../components/NotFound'
import ReportHeader from '../../../../components/ReportHeader'
import ReportSidebar from '../../../../components/ReportSidebar'
import type { Section } from '../../../../components/ReportSidebar/SidebarSection'
import SidebarModal from '../../../../components/SidebarModal'
import SidebarToggleButton from '../../../../components/SidebarToggleButton'
import { Path } from '../../../../utils/constants'
import Remuneration from '../../components/Remuneration'
import { useThemesPath } from '../../utils/hooks'
import Controversies from '../Controversies'

interface Props {
  className?: string
}

const ThemesReport: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { theme } = useThemesPath()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const sidebarSections = useMemo(
    () => [
      {
        path: '/remuneration',
        label: t('Commons.remuneration'),
        subsections: [
          {
            path: '/remuneration',
            label: t('Pages.ThemesReport.standard'),
          },
          {
            path: '/remuneration/sum',
            label: t('Pages.ThemesReport.sum'),
          },
        ],
      },
      {
        path: '/controversies',
        label: t('Commons.controversies'),
        permissions: ['v2:esg_reports:themes:controversies'],
        autoRedirect: true,
        subsections: [
          {
            path: '/controversies/search',
            label: t('Commons.search'),
            permissions: ['v2:esg_reports:themes:controversies:search'],
          },
          {
            path: '/controversies/library',
            label: t('Pages.ThemesReport.library'),
            permissions: ['v2:esg_reports:themes:controversies:library'],
          },
        ],
      },
    ],
    [t]
  )

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState)
  }

  if (!theme) {
    return <Redirect to={[Path.REPORTS_THEMES, 'remuneration'].join('/')} />
  }

  const renderBody = () => {
    switch (theme) {
      case 'remuneration':
        return <Remuneration />
      case 'controversies':
        return <Controversies />
      default:
        return <NotFound />
    }
  }

  return (
    <>
      <ReportHeader className="flex-none" type="themes" />
      <div className="flex justify-end p-4 lg:hidden">
        <SidebarToggleButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
      </div>

      <div className="flex flex-grow flex-col justify-center md:flex-row">
        <div
          className={classNames(
            'w-full md:w-auto flex-shrink-0',
            'px-4 md:px-0',
            'md:max-w-xs',
            props.className
          )}
        >
          <div className="hidden lg:mt-4 lg:block lg:w-64">
            <ReportSidebar
              className="w-full bg-white lg:mt-4"
              basePath={Path.REPORTS_THEMES}
              sections={sidebarSections as Section[]}
            />
          </div>
        </div>
        <div className="mt-8 w-full flex-grow flex-col">{renderBody()}</div>
      </div>
      <SidebarModal isOpen={isSidebarOpen} onClose={toggleSidebar}>
        <ReportSidebar
          className="mt-4 w-full bg-white"
          basePath={Path.REPORTS_THEMES}
          sections={sidebarSections as Section[]}
          toggleSidebar={toggleSidebar}
        />
      </SidebarModal>
    </>
  )
}

export default ThemesReport
