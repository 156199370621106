import 'ag-grid-enterprise'

import { prop } from 'ramda'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { v4 as uuid } from 'uuid'

import Button from '../../../../components/Button'
import LabeledComponent from '../../../../components/LabeledComponent'
import ReportHeader from '../../../../components/ReportHeader'
import Spinner from '../../../../components/Spinner'
import { Path } from '../../../../utils/constants'
import { SELECT_STYLES } from '../../../PersonalizedReport/utils/constants'
import { getStatisticsData } from '../../clients'
import type {
  MetricInput,
  MetricType,
  ScoreStatisticsMetric,
  ScoreStatisticsMetrics,
} from '../../typings/metrics'
import type { ChartConfig } from './Chart'
import Chart from './Chart'
import Table from './Table'

interface Props {
  className?: string
  companies: Pick<Company, 'id' | 'name'>[]
  peerGroups: string[]
  userBaskets: Pick<UserBasket, 'id' | 'name'>[]
  metrics: ScoreStatisticsMetrics
  frameworksYearRange: {
    min: number
    max: number
  }
}

interface CompanyOption extends SelectOption<string> {
  type: 'company'
  id: string
}

interface PeerGroupOption extends SelectOption<string> {
  type: 'peerGroup'
  name: string
}

interface BasketOption extends SelectOption<string> {
  type: 'basket'
  id: string
}

interface MetricOption extends SelectOption<string> {
  metric: MetricInput
}

type IncludeOption = CompanyOption | PeerGroupOption | BasketOption
type ExcludeOption = CompanyOption | PeerGroupOption | BasketOption
type SelectElement<T> = { state: { value: T | null } }

const Statistics: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [yearOption, setYearOption] = useState<{
    start?: SelectOption<number>
    end?: SelectOption<number>
  }>({})
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [statisticsData, setStatisticsData] = useState<Awaited<
    ReturnType<typeof getStatisticsData>
  > | null>(null)
  const [chartConfig, setChartConfig] = useState<ChartConfig | null>(null)

  const includeSelectRef = useRef<SelectElement<IncludeOption[]>>(null)
  const excludeSelectRef = useRef<SelectElement<ExcludeOption[]>>(null)
  const metricSelectRef = useRef<SelectElement<MetricOption[]>>(null)

  const companyOptions: CompanyOption[] = useMemo(
    () =>
      props.companies
        .map(({ id, name }) => ({
          label: name,
          value: id,
          type: 'company' as const,
          id,
        }))
        .sort((cA, cB) => cA.label.localeCompare(cB.label)),
    [props.companies]
  )

  const peerGroupOptions: PeerGroupOption[] = useMemo(
    () =>
      props.peerGroups
        .map((name) => ({
          label: name,
          value: name,
          type: 'peerGroup' as const,
          name,
        }))
        .sort((pA, pB) => pA.label.localeCompare(pB.label)),
    [props.peerGroups]
  )

  const basketOptions: BasketOption[] = useMemo(
    () =>
      props.userBaskets
        .map(({ id, name }) => ({
          label: name,
          value: id,
          type: 'basket' as const,
          id,
        }))
        .sort((bA, bB) => bA.label.localeCompare(bB.label)),
    [props.userBaskets]
  )

  const metricGroupOptions: { label: string; options: MetricOption[] }[] =
    useMemo(() => {
      const makeOption = (
        metric: ScoreStatisticsMetric,
        makeLabel: (name: string) => string,
        type: MetricType
      ) => ({
        label: makeLabel(metric.name),
        value: uuid(),
        metric: {
          path: metric.path,
          type,
        },
      })

      return [
        {
          label: 'Scores',
          options: props.metrics.quali
            .map((metric) =>
              makeOption(metric, (name) => `Score ${name}`, 'quali-score')
            )
            .sort((m1, m2) =>
              m1.metric.path.length === m2.metric.path.length
                ? m1.label.localeCompare(m2.label)
                : m1.metric.path.length - m2.metric.path.length
            ),
        },
        {
          label: t('Commons.weights'),
          options: props.metrics.quali
            .filter((metric) => metric.path.length > 0)
            .map((metric) =>
              makeOption(
                metric,
                (name) => `${t('Commons.weights')} ${name}`,
                'quali-weight'
              )
            )
            .sort((m1, m2) =>
              m1.metric.path.length === m2.metric.path.length
                ? m1.label.localeCompare(m2.label)
                : m1.metric.path.length - m2.metric.path.length
            ),
        },
        {
          label: t('Pages.ScoreStatistics.quantitativeValues'),
          options: props.metrics.quant
            .map((metric) => makeOption(metric, (name) => name, 'quant'))
            .sort((m1, m2) => m1.label.localeCompare(m2.label)),
        },
      ]
    }, [props.metrics, t])

  const yearOptions = useMemo(() => {
    const numOptions =
      props.frameworksYearRange.max - props.frameworksYearRange.min + 1

    return Array.from({ length: numOptions }).map((_, idx) => {
      const year = props.frameworksYearRange.min + idx
      return { label: year.toString(), value: year }
    })
  }, [props.frameworksYearRange])

  useEffect(() => {
    if (yearOptions.length > 0) {
      setYearOption({
        start: yearOptions[0],
        end: yearOptions[yearOptions.length - 1],
      })
    }
  }, [yearOptions])

  const isLoading = yearOptions.length === 0

  const handleSubmit = useCallback(() => {
    if (
      !includeSelectRef.current ||
      !excludeSelectRef.current ||
      !metricSelectRef.current ||
      !yearOption.start ||
      !yearOption.end
    ) {
      return
    }

    const includeOptions = includeSelectRef.current.state.value ?? []
    const excludeOptions = excludeSelectRef.current.state.value ?? []
    const metricOptions = metricSelectRef.current.state.value

    const groupOptions = (options: IncludeOption[] | ExcludeOption[]) => {
      const companyOptions: CompanyOption[] = []
      const peerGroupOptions: PeerGroupOption[] = []
      const basketOptions: BasketOption[] = []

      options.forEach((option) => {
        switch (option.type) {
          case 'company':
            companyOptions.push(option)
            break
          case 'peerGroup':
            peerGroupOptions.push(option)
            break
          case 'basket':
            basketOptions.push(option)
            break
        }
      })

      return [companyOptions, peerGroupOptions, basketOptions] as const
    }

    const [
      includeCompanyOptions,
      includePeerGroupOptions,
      includeBasketOptions,
    ] = groupOptions(includeOptions)
    const [
      excludeCompanyOptions,
      excludePeerGroupOptions,
      excludeBasketOptions,
    ] = groupOptions(excludeOptions)

    setChartConfig(null)
    setLoadingData(true)
    getStatisticsData({
      include: {
        companies: includeCompanyOptions.map(prop('id')),
        peerGroups: includePeerGroupOptions.map(prop('name')),
        baskets: includeBasketOptions.map(prop('id')),
      },
      exclude: {
        companies: excludeCompanyOptions.map(prop('id')),
        peerGroups: excludePeerGroupOptions.map(prop('name')),
        baskets: excludeBasketOptions.map(prop('id')),
      },
      metrics: metricOptions?.map(prop('metric')) ?? [],
      yearRange: {
        min: yearOption.start.value,
        max: yearOption.end.value,
      },
    })
      .then(setStatisticsData)
      .finally(() => setLoadingData(false))
  }, [yearOption])

  const handleTableHeaderClick = useCallback((field: string) => {
    setChartConfig({ type: 'metric', subject: field })
  }, [])

  const handleTableCompanyClick = useCallback((companyName: string) => {
    setChartConfig({ type: 'company', subject: companyName })
  }, [])

  // TODO: Improve search on Selects

  return (
    <div className={props.className}>
      <ReportHeader type="statistics" />
      <div className="relative flex justify-center bg-gray-100 py-8">
        {isLoading && (
          <div className="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-60">
            <Spinner className="text-jgp-success-main" />
          </div>
        )}
        <div className="flex w-full max-w-screen-xl flex-col items-stretch gap-y-4 px-4">
          <LabeledComponent
            className="w-full"
            label={t('Pages.ScoreStatistics.includeSectorsCompaniesOrBaskets')}
          >
            <Select
              ref={includeSelectRef as any}
              isMulti
              styles={SELECT_STYLES}
              placeholder={t('Pages.ScoreStatistics.select')}
              options={[
                { label: t('Commons.baskets'), options: basketOptions },
                { label: t('Commons.sectors'), options: peerGroupOptions },
                { label: t('Commons.companies'), options: companyOptions },
              ]}
            />
          </LabeledComponent>
          <LabeledComponent
            className="w-full"
            label={t(
              'Pages.ScoreStatistics.excludeCompaniesOrSectorsFromTheFirstSelection'
            )}
          >
            <Select
              ref={excludeSelectRef as any}
              isMulti
              styles={SELECT_STYLES}
              placeholder={t('Pages.ScoreStatistics.select')}
              options={[
                { label: t('Commons.companies'), options: companyOptions },
                { label: t('Commons.sectors'), options: peerGroupOptions },
                { label: t('Commons.baskets'), options: basketOptions },
              ]}
            />
          </LabeledComponent>
          <LabeledComponent
            className="w-full"
            label={t('Pages.ScoreStatistics.selectIndicatorsForAnalysis')}
          >
            {/* TODO: Virtualize Select to improve performance */}
            <Select
              ref={metricSelectRef as any}
              isLoading={props.metrics.quant.length === 0}
              loadingMessage={() => t('Pages.ScoreStatistics.loadingMetrics')}
              isMulti
              styles={SELECT_STYLES}
              placeholder={t('Pages.ScoreStatistics.select')}
              options={metricGroupOptions}
            />
          </LabeledComponent>
          <div className="flex items-center lg:gap-x-4">
            <LabeledComponent
              className="w-44"
              label={t('Pages.ScoreStatistics.startYear')}
            >
              <Select
                isLoading={!yearOption}
                styles={SELECT_STYLES}
                options={yearOptions}
                placeholder={t('Pages.ScoreStatistics.select')}
                value={yearOption?.start}
                onChange={(value) =>
                  value && setYearOption((cur) => ({ ...cur, start: value }))
                }
              />
            </LabeledComponent>
            <LabeledComponent
              className="w-44"
              label={t('Pages.ScoreStatistics.endYear')}
            >
              <Select
                isLoading={!yearOption}
                styles={SELECT_STYLES}
                options={yearOptions}
                defaultValue={yearOptions[yearOptions.length - 1]}
                placeholder={t('Pages.ScoreStatistics.select')}
                value={yearOption?.end}
                onChange={(value) =>
                  value && setYearOption((cur) => ({ ...cur, end: value }))
                }
              />
            </LabeledComponent>
          </div>
          <div className="flex items-center justify-end gap-x-8">
            <Link
              className="text-jgp-secondary-dark hover:underline"
              to={Path.REPORTS_PERSONALIZED_BASKETS}
            >
              {t('Commons.manageBaskets')}
            </Link>
            <Button
              className="px-4 py-2"
              variant="primary"
              onClick={handleSubmit}
            >
              {t('Pages.ScoreStatistics.submit')}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex justify-center py-4">
        <div className="relative w-full max-w-screen-xl px-4">
          {loadingData && (
            <div className="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-60 py-8">
              <Spinner className="text-jgp-success-main" />
            </div>
          )}
          {statisticsData && (
            <Table
              statisticsData={statisticsData}
              onHeaderClick={handleTableHeaderClick}
              onCompanyClick={handleTableCompanyClick}
            />
          )}
        </div>
      </div>
      {chartConfig && statisticsData && (
        <div className="flex justify-center py-4">
          <Chart config={chartConfig} statisticsData={statisticsData} />
        </div>
      )}
    </div>
  )
}

export default Statistics
