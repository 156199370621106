import React from 'react'

import { MenuIcon, XIcon } from '../icons'

interface SidebarToggleButtonProps {
  isOpen: boolean
  onClick: () => void
}

const SidebarToggleButton: React.FC<SidebarToggleButtonProps> = ({
  isOpen,
  onClick,
}) => {
  return (
    <button
      className="rounded-full bg-jgp-success-main p-2 text-white focus:outline-none focus:ring-2"
      onClick={onClick}
    >
      {isOpen ? (
        <XIcon className="h-6 w-6" />
      ) : (
        <MenuIcon className="h-6 w-6" />
      )}
    </button>
  )
}

export default SidebarToggleButton
