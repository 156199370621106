import { RegularChart } from '@jgp-er-dev/charts'
import classNames from 'classnames'
import React, { useMemo } from 'react'

export interface Props {
  subsections: {
    name: string
    score: number
  }[]
  title?: string
  className?: string
}

export const getChartConfig = (props: Props) => {
  const series = [
    {
      name: 'Score',
      data: props.subsections.map((subsection) => ({
        name: subsection.name,
        y: subsection.score,
      })),
      tooltip: {
        valueDecimals: 0,
      },
      color: '#30ab09',
    },
  ]

  return {
    options: {
      chart: { type: 'column' as const },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '{point.y:.0f}',
            inside: false,
          },
        },
      },
      series: series as any,
      title: { text: props.title ?? 'Notas em cada tópico' },
      credits: { text: '' },
      legend: { enabled: false },
      xAxis: { type: 'category' as const },
      yAxis: {
        title: { text: '' },
      },
      navigation: {
        buttonOptions: { enabled: false },
      },
    },
  }
}

const SubsectionScoreChart: React.FC<Props> = (props) => {
  const chartProps = useMemo(() => getChartConfig(props), [props])

  return (
    <div
      className={classNames(
        'relative grid h-chart grid-cols-1 p-2',
        props.className
      )}
    >
      <RegularChart chartProps={chartProps} />
    </div>
  )
}

export default SubsectionScoreChart
