import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CompanySectorList from '../../../../components/CompanySectorList'
import { useWatchLoading } from '../../../../contexts/Loading'
import { slugify } from '../../../../utils'
import { Path } from '../../../../utils/constants'

interface Props {
  loading: boolean
  companyList: CompanyBase[]
  sectorList: string[]
  favoriteCompanies: string[]
  favoriteSectors: string[]
  notifiedCompanies: string[]
  notifiedSectors: string[]
  className?: string
  onFavoriteChange?: (
    subject: string,
    type: 'COMPANY' | 'SECTOR',
    value: boolean
  ) => Promise<void>
  onNotificationChange?: (
    subject: string,
    type: 'COMPANY' | 'SECTOR',
    value: boolean
  ) => Promise<void>
}

const Config: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  useWatchLoading(props.loading)

  const companyListItems = useMemo(() => {
    const companyList = props.companyList
    const favorites = props.favoriteCompanies
    const notifications = props.notifiedCompanies
    const onFavoriteChange = props.onFavoriteChange
    const onNotificationChange = props.onNotificationChange

    return companyList.map((company) => {
      const slug = slugify(company.name)
      return {
        isFavorite: favorites.includes(slug),
        isNotified: notifications.includes(slug),
        label: company.name,
        url: `${Path.REPORTS_COMPANY}/${slug}`,
        onFavoriteChange: async (value: boolean) =>
          onFavoriteChange?.(slug, 'COMPANY', value),
        onNotificationChange: async (value: boolean) =>
          onNotificationChange?.(slug, 'COMPANY', value),
      }
    })
  }, [
    props.companyList,
    props.favoriteCompanies,
    props.notifiedCompanies,
    props.onFavoriteChange,
    props.onNotificationChange,
  ])

  const sectorListItems = useMemo(() => {
    const sectorList = props.sectorList
    const favorites = props.favoriteSectors
    const notifications = props.notifiedSectors
    const onFavoriteChange = props.onFavoriteChange
    const onNotificationChange = props.onNotificationChange

    return sectorList.map((sector) => {
      const slug = slugify(sector)
      return {
        isFavorite: favorites.includes(slug),
        isNotified: notifications.includes(slug),
        label: sector,
        url: `${Path.REPORTS_SECTORAL}/${slug}`,
        onFavoriteChange: async (value: boolean) =>
          onFavoriteChange?.(slug, 'SECTOR', value),
        onNotificationChange: async (value: boolean) =>
          onNotificationChange?.(slug, 'SECTOR', value),
      }
    })
  }, [
    props.sectorList,
    props.favoriteSectors,
    props.notifiedSectors,
    props.onFavoriteChange,
    props.onNotificationChange,
  ])

  return (
    <div className={props.className}>
      <div className="flex h-26 justify-center bg-jgp-success-main">
        <div className="flex w-full max-w-screen-xl items-center px-4 font-work-sans text-3xl font-semibold">
          {t('Commons.settings')}
        </div>
      </div>
      <div className="flex flex-col items-center">
        <CompanySectorList
          className="mt-8 w-full max-w-screen-xl"
          title={t('Commons.companies')}
          items={companyListItems}
        />
        <CompanySectorList
          className="mt-16 w-full max-w-screen-xl"
          title={t('Commons.sectors')}
          items={sectorListItems}
        />
      </div>
    </div>
  )
}

export default Config
