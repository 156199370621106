import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

interface Commons {
  analyse: string
  analysis: string
  anUnexpectedErrorHasOccurred: string
  avarege: string
  basket: string
  baskets: string
  cancel: string
  category: string
  client: string
  close: string
  climateChange: string
  comment: string
  companies: string
  company: string
  confirm: string
  controversies: string
  dams: string
  data: string
  delete: string
  diversity: string
  environmental: string
  framework: string
  goBack: string
  governance: string
  help: string
  indicator: string
  load: string
  manage: string
  manageBaskets: string
  metric: string
  mode: string
  name: string
  no: string
  notes: string
  noDataToDisplay: string
  overview: string
  pleaseTryAgain: string
  pleaseTryAgainLater: string
  qualitative: string
  quantitative: string
  remuneration: string
  resourceUsage: string
  save: string
  search: string
  sectors: string
  settings: string
  social: string
  source: string
  statistics: string
  subsection: string
  ticker: string
  themes: string
  values: string
  wasteAndEffluentManagement: string
  weight: string
  weights: string
  year: string
  yes: string
}

interface ESGPlatformHeader {
  platformHeader: string
}

interface Footer {
  aboutUs: string
  allFunds: string
  allRightsReserved: string
  esg: string
  investmentProcess: string
  investmentPhilosophy: string
  howToInvest: string
  managementReports: string
  privacyPolicy: string
  regulatoryInformation: string
  services: string
  sharesAndReturns: string
  termsAndConditions: string
  the: string
  whoWeAre: string
}

interface LinkGrid {
  openReferenceForm: string
  referenceFormNotAvailable: string
}

interface MenuDrawer {
  customizations: string
  methodologyAndPolicies: string
  myAccount: string
}

interface NotFound {
  pageNotFound: string
}

interface ReportHeader {
  addToFavorites: string
  businessAnalysis: string
  customAnalysis: string
  favorite: string
  removeFromFavorites: string
  sectorAnalysis: string
}

interface NotificationButton {
  disableNotifications: string
  enableNotifications: string
  notification: string
}

interface TopBar {
  options: {
    customize: string
    home: string
  }
  userMenu: {
    notifications: string
    settings: string
  }
}

interface CompanyReport {
  answers: string
  basicSanitation: string
  businessAnalysis: string
  criteria: string
  customization: string
  decarbonization: string
  eraseCustomizations: string
  goals: string
  governance: string
  justificationDisclaimer: string
  noControversiesForThisCompany: string
  peersAverage: string
  ranking: string
  reportName: string
  score: string
  sections: string
  selectCompanies: string
  selectCompany: string
  selectSector: string
  social: string
  topics: string
}

interface ComparativeReport {
  averagePay: string
  boardIndependence: string
  council: string
  directors: string
  femaleEmployees: string
  womenOnTheBoard: string
  womenOnTheCouncil: string
}

interface Login {
  email: string
  enter: string
  forgotPassword: string
  incorrectUsernameOrPassword: string
  password: string
  register: string
  remindMe: string
  tooManyRequests: string
  youDoNotHavePermissionToAccessThisPlatform: string
}

interface PersonalizedReport {
  addFilter: string
  analysisName: string
  analysisSavedSuccessfully: string
  anErrorOccurredWhileDeletingTheAnalysis: string
  anErrorOccurredWhileDeletingTheBasket: string
  anErrorOccurredWhileGeneratingTheShareLink: string
  anErrorOccurredWhileSavingTheAnalysis: string
  anErrorOccurredWhileSavingTheBasket: string
  anErrorOccurredWhileTryingToRemoveTheReport: string
  anErrorOccurredWhileTryingToRenameTheReport: string
  basketName: string
  basketSavedSuccessfully: string
  basketSharedMessage: string
  calculation: string
  chooseBasket: string
  chooseCalculation: string
  chooseFilter: string
  chooseIndicator: string
  choosePonderation: string
  chooseSection: string
  chooseSubsection: string
  chooseValues: string
  createNewBasket: string
  deleteAnalysis: string
  deleteBasket: string
  filter: string
  manageAnalysis: string
  ponderation: string
  reportSharedMessage: string
  saveAnalysis: string
  share: string
  theAnalysisHasBeenDeleted: string
  theBasketHasBeenDeleted: string
  thereAreNoResultsToDisplay: string
  writeTheNameOfTheAnalysis: string
  writeTheNameOfTheBasket: string
}

interface Reports {
  favoritesFeminine: string
  favoritesMasculine: string
  open: string
  recentlyViewedFeminine: string
  recentlyViewedMasculine: string
  suggestions: string
}

interface ScoreStatistics {
  endYear: string
  excludeCompaniesOrSectorsFromTheFirstSelection: string
  exportAsXlsx: string
  includeSectorsCompaniesOrBaskets: string
  loadingMetrics: string
  quantitativeValues: string
  select: string
  selectIndicatorsForAnalysis: string
  startYear: string
  submit: string
}

interface SectoralReports {
  details: string
  evolution: string
  thereAreNoControversiesRecordedForCompaniesInThisSector: string
  thereIsNoDataForThisSector: string
}

interface ThemesReport {
  aDifferentReportWithThisNameAlreadyExists: string
  aReportWithThisNameAlreadyExistsDoYouWantToOverwriteIt: string
  addParameter: string
  addSearch: string
  administrativeBoard: string
  agoProposal: string
  anErrorOccurredWhileLoadingTheReport: string
  anErrorOccurredWhileSavingTheReport: string
  apply: string
  averageBoardCompensation: string
  averageCompensation: string
  averageCouncilRemuneration: string
  beforeRegisteringANewCompanyCheckInCompassWhetherTheCompanyAlreadyExistsWithAnAlternativeName: string
  board: string
  boardCompensationMktCap: string
  boardRemunerationBRLmi: string
  boardRemunerationEBITDA: string
  boardRemunerationProfit: string
  cancel: string
  checkCompanyExistsInCompass: string
  companySuccessfullyCreated: string
  compensationBreakdownBoard: string
  compensationBreakdownCouncil: string
  compensationEBITDA: string
  compensationMktCap: string
  compensationProfit: string
  controversy: string
  controversyLibrary: string
  controversySuccessfullyRemoved: string
  correctData: string
  councilRemunerationBRLmi: string
  councilRemunerationEBITDA: string
  councilRemunerationProfit: string
  customizeSearch: string
  deleteReport: string
  doYouReallyWantToDeleteTheReport: string
  downloadDataInXLSX: string
  enableAlertsWithTickerCNPJ: string
  enterNewReportName: string
  enterTheNameOfTheReportToBeSaved: string
  estimatesForTheYearOf: string
  fillingInTheTickerCNPJIsNotMandatoryButAllowsAlertsToBeIssuedWhenADisputeIsRegisteredInTheSystem: string
  fixedCompensation: string
  history: string
  includeYearsWithIncompleteDataInTheSum: string
  invalidCnpj: string
  latest: string
  library: string
  link: string
  longTermCompensation: string
  markAsSeriousControversy: string
  markAsControversial: string
  markControversy: string
  marked: string
  maximumAndMinimumCompensationBoard: string
  maximumAndMinimumCompensationCouncil: string
  maximumCompensation: string
  minimumCompensation: string
  nameCannotBeEmpty: string
  nameIsRequired: string
  noResultsFound: string
  overwrite: string
  peerGroup: string
  peerGroupIsMandatory: string
  prospective: string
  registerNewCompany: string
  remuneration: string
  removeControversy: string
  removedFeminine: string
  removeFromSeriousControversies: string
  rename: string
  renameReport: string
  reportLoadedSuccessfully: string
  reportRenamedSuccessfully: string
  reportRemovedSuccessfully: string
  reportSavedSuccessfully: string
  reset: string
  saveReport: string
  searchForControversies: string
  searchInterval: string
  searchParameters: string
  searchText: string
  searchType: string
  selectSearch: string
  selectCompany: string
  selectTheCompaniesToBeAdded: string
  selectThePeers: string
  snippet: string
  successfully: string
  standard: string
  sum: string
  thereAreNoControversiesToDisplay: string
  thereIsAlreadyACompanyRegisteredWithThisName: string
  title: string
  totalBoardCompensation: string
  totalCompensation: string
  totalCouncilCompensation: string
  type: string
  undoChanges: string
  uploadReport: string
  unmarkAsControversial: string
  useOriginalValues: string
  variableCompensation: string
  youHaveNoSavedReports: string
}

interface Translation {
  Commons: Commons
  Components: {
    ESGPlatformHeader: ESGPlatformHeader
    Footer: Footer
    LinkGrid: LinkGrid
    MenuDrawer: MenuDrawer
    NotFound: NotFound
    ReportHeader: ReportHeader
    NotificationButton: NotificationButton
    TopBar: TopBar
  }
  Pages: {
    CompanyReport: CompanyReport
    ComparativeReport: ComparativeReport
    Login: Login
    PersonalizedReport: PersonalizedReport
    Reports: Reports
    ScoreStatistics: ScoreStatistics
    SectoralReports: SectoralReports
    ThemesReport: ThemesReport
  }
}

interface Resource {
  [languageCode: string]: { translation: Translation }
}

const resources: Resource = {
  en: {
    translation: {
      Commons: {
        analyse: 'Analyze',
        analysis: 'Analysis',
        anUnexpectedErrorHasOccurred: 'An unexpected error has occurred.',
        avarege: 'Average',
        basket: 'Basket',
        baskets: 'Baskets',
        cancel: 'Cancel',
        category: 'Category',
        client: 'Client',
        close: 'Close',
        climateChange: 'Climate change',
        comment: 'Comment',
        companies: 'Companies',
        company: 'Company',
        controversies: 'Controversies',
        confirm: 'Confirm',
        data: 'Data',
        dams: 'Dams',
        delete: 'Delete',
        diversity: 'Diversity',
        environmental: 'Environmental',
        framework: 'Framework',
        goBack: 'Go back',
        governance: 'Governance',
        help: 'Help',
        indicator: 'Indicator',
        load: 'Load',
        manage: 'Manage',
        manageBaskets: 'Manage baskets',
        metric: 'Metric',
        mode: 'Mode',
        name: 'name',
        no: 'No',
        notes: 'Notes',
        noDataToDisplay: 'No data to display',
        overview: 'Overview',
        pleaseTryAgain: 'Please try again.',
        pleaseTryAgainLater: 'Please try again later.',
        qualitative: 'Qualitative',
        quantitative: 'Quantitative',
        remuneration: 'Remuneration',
        resourceUsage: 'Resource usage',
        save: 'Save',
        search: 'Search',
        sectors: 'Sectors',
        settings: 'Settings',
        social: 'Social',
        source: 'Source',
        statistics: 'Statistics',
        subsection: 'Subsection',
        themes: 'Themes',
        ticker: 'Ticker',
        values: 'Values',
        wasteAndEffluentManagement: 'Waste and effluent management',
        weight: 'Weight',
        weights: 'Weights',
        year: 'Year',
        yes: 'Yes',
      },
      Components: {
        ESGPlatformHeader: {
          platformHeader: "<span className='font-light'>ESG</span> Platform",
        },
        Footer: {
          aboutUs: 'About Us',
          allFunds: 'All Funds',
          allRightsReserved: 'All rights reserved',
          esg: 'ESG',
          investmentProcess: 'Investment Process',
          investmentPhilosophy: 'Investment Philosophy',
          howToInvest: 'How to Invest',
          managementReports: 'Management Reports',
          privacyPolicy: 'Privacy Policy',
          regulatoryInformation: 'Regulatory Information',
          services: 'Services',
          sharesAndReturns: 'Shares and Returns',
          termsAndConditions: 'Terms and Conditions',
          the: 'The',
          whoWeAre: 'Who We Are',
        },
        LinkGrid: {
          openReferenceForm: 'Open reference form',
          referenceFormNotAvailable: 'Reference form not available',
        },
        MenuDrawer: {
          customizations: 'Customizations',
          methodologyAndPolicies: 'Methodology and Policies',
          myAccount: 'My Account',
        },
        NotFound: {
          pageNotFound: 'Page not found',
        },
        ReportHeader: {
          addToFavorites: 'Add to favorites',
          businessAnalysis: 'Business Analysis',
          customAnalysis: 'Custom Analysis',
          favorite: 'Favorite',
          removeFromFavorites: 'Remove from favorites',
          sectorAnalysis: 'Sector Analysis',
        },
        NotificationButton: {
          enableNotifications: 'Enable notifications',
          disableNotifications: 'Disable notifications',
          notification: 'Notification',
        },
        TopBar: {
          options: {
            customize: 'Customize',
            home: 'Home',
          },
          userMenu: {
            notifications: 'Notifications',
            settings: 'Settings',
          },
        },
      },
      Pages: {
        CompanyReport: {
          answers: 'Answers',
          basicSanitation: 'Basic sanitation',
          businessAnalysis: 'Business Analysis',
          criteria: 'Criteria',
          customization: 'Customization',
          decarbonization: 'Decarbonization',
          eraseCustomizations: 'Erase customizations',
          goals: 'Goals',
          governance: 'Governance',
          justificationDisclaimer: 'Justification/Disclaimer',
          noControversiesForThisCompany:
            'There are no controversies registered for this company.',
          peersAverage: 'Peers Average',
          ranking: 'Ranking',
          reportName: 'Report name',
          score: 'Score',
          sections: 'Sections',
          selectCompanies: 'Select companies...',
          selectCompany: 'Select company...',
          selectSector: 'Select sector...',
          social: 'Social',
          topics: 'Topics',
        },
        ComparativeReport: {
          averagePay: 'Average pay',
          boardIndependence: 'Board independence',
          council: 'Council',
          directors: 'Directors',
          femaleEmployees: 'Female employees',
          womenOnTheBoard: 'Women on the board',
          womenOnTheCouncil: 'Women on the council',
        },
        Login: {
          email: 'Email',
          enter: 'Enter',
          forgotPassword: 'Forgot password',
          incorrectUsernameOrPassword: 'Incorrect username or password',
          password: 'Password',
          register: 'Register',
          remindMe: 'Remind me',
          tooManyRequests: 'Too many requests.',
          youDoNotHavePermissionToAccessThisPlatform:
            'You do not have permission to access this platform',
        },
        PersonalizedReport: {
          addFilter: 'Add filter',
          analysisName: 'Analysis name',
          analysisSavedSuccessfully: 'Analysis saved successfully',
          anErrorOccurredWhileDeletingTheAnalysis:
            'An error occurred while deleting the analysis.',
          anErrorOccurredWhileDeletingTheBasket:
            'An error occurred while deleting the basket.',
          anErrorOccurredWhileGeneratingTheShareLink:
            'An error occurred while generating the share link.',
          anErrorOccurredWhileSavingTheAnalysis:
            'An error occurred while saving the analysis.',
          anErrorOccurredWhileSavingTheBasket:
            'An error occurred while saving the basket.',
          anErrorOccurredWhileTryingToRemoveTheReport:
            'An error occurred while trying to remove the report',
          anErrorOccurredWhileTryingToRenameTheReport:
            'An error occurred while trying to rename the report',
          basketName: 'Basket name',
          basketSavedSuccessfully: 'Basket saved successfully',
          basketSharedMessage:
            'A shareable basketball link has been created for your clipboard',
          calculation: 'Calculation',
          chooseBasket: 'Choose basket',
          chooseCalculation: 'Choose calculation',
          chooseFilter: 'Choose filter',
          chooseIndicator: 'Choose indicator',
          choosePonderation: 'Choose ponderation',
          chooseSection: 'Choose section',
          chooseSubsection: 'Choose subsection',
          chooseValues: 'Choose values',
          createNewBasket: 'Create new basket',
          deleteAnalysis: 'Delete analysis',
          deleteBasket: 'Delete basket',
          filter: 'Filter',
          manageAnalysis: 'Manage analyses',
          ponderation: 'Ponderation',
          reportSharedMessage:
            'A shareable report link has been created for your clipboard',
          saveAnalysis: 'Save analysis',
          share: 'Share',
          theAnalysisHasBeenDeleted: 'The analysis has been deleted',
          theBasketHasBeenDeleted: 'The basket has been deleted',
          thereAreNoResultsToDisplay: 'There are no results to display',
          writeTheNameOfTheAnalysis: 'Write the name of the analysis',
          writeTheNameOfTheBasket: 'Write the name of the basket',
        },
        Reports: {
          favoritesFeminine: 'Favorites',
          favoritesMasculine: 'Favorites',
          open: 'Open',
          recentlyViewedFeminine: 'Recently viewed',
          recentlyViewedMasculine: 'Recently viewed',
          suggestions: 'Suggestions',
        },
        ScoreStatistics: {
          endYear: 'endYear',
          excludeCompaniesOrSectorsFromTheFirstSelection:
            'Exclude companies or sectors from the first selection',
          exportAsXlsx: 'Export as .xlsx',
          includeSectorsCompaniesOrBaskets:
            'Include sectors, companies, or baskets',
          loadingMetrics: 'Loading metrics...',
          quantitativeValues: 'Quantitative values',
          select: 'Select...',
          selectIndicatorsForAnalysis: 'Select indicators for analysis',
          startYear: 'Start year',
          submit: 'Submit',
        },
        SectoralReports: {
          details: 'Details',
          evolution: 'Evolution',
          thereAreNoControversiesRecordedForCompaniesInThisSector:
            'There are no controversies recorded for companies in this sector.',
          thereIsNoDataForThisSector: 'There is no data for this sector.',
        },
        ThemesReport: {
          aDifferentReportWithThisNameAlreadyExists:
            'A different report with this name already exists',
          aReportWithThisNameAlreadyExistsDoYouWantToOverwriteIt:
            'A report with this name already exists. Do you want to overwrite it?',
          addParameter: 'Add parameter',
          addSearch: 'Add search',
          administrativeBoard: 'Administrative Board',
          agoProposal: 'AGO Proposal',
          anErrorOccurredWhileLoadingTheReport:
            'An error occurred while loading the report',
          anErrorOccurredWhileSavingTheReport:
            'An error occurred while saving the report',
          apply: 'Apply',
          averageBoardCompensation: 'Average Board Compensation',
          averageCompensation: 'Average Compensation',
          averageCouncilRemuneration: 'Average Council Remuneration',
          beforeRegisteringANewCompanyCheckInCompassWhetherTheCompanyAlreadyExistsWithAnAlternativeName:
            'Before registering a new company, check in Compass whether the company already exists with an alternative name.',
          board: 'Board',
          boardCompensationMktCap: 'Board Compensation/Mkt Cap',
          boardRemunerationBRLmi: 'Board Remuneration (mi BRL)',
          boardRemunerationEBITDA: 'Board Remuneration/EBITDA',
          boardRemunerationProfit: 'Board Remuneration/Profit',
          cancel: 'Cancel',
          checkCompanyExistsInCompass:
            'Before registering a new company, check in Compass whether the company already exists with an alternative name.',
          companySuccessfullyCreated: 'Company successfully created',
          compensationBreakdownBoard: 'Compensation Breakdown - Board',
          compensationBreakdownCouncil: 'Compensation Breakdown - Council',
          compensationEBITDA: 'Compensation/EBITDA',
          compensationMktCap: 'Compensation/Mkt Cap',
          compensationProfit: 'Compensation/Profit',
          controversy: 'Controversy',
          controversyLibrary: 'Controversy Library',
          controversySuccessfullyRemoved: 'Controversy successfully removed',
          correctData: 'Correct data',
          councilRemunerationBRLmi: 'Council Remuneration (mi BRL)',
          councilRemunerationEBITDA: 'Council Remuneration/EBITDA',
          councilRemunerationProfit: 'Council Remuneration/Profit',
          customizeSearch: 'Customize Search',
          deleteReport: 'Delete Report',
          doYouReallyWantToDeleteTheReport:
            'Do you really want to delete the report',
          downloadDataInXLSX: 'Download Data in XLSX',
          enableAlertsWithTickerCNPJ:
            'Filling in the ticker/CNPJ is not mandatory but allows the issuance of alerts when a dispute is registered in the system.',
          enterNewReportName: 'Enter new report name',
          enterTheNameOfTheReportToBeSaved:
            'Enter the name of the report to be saved',
          estimatesForTheYearOf: 'Estimates for the year of',
          fillingInTheTickerCNPJIsNotMandatoryButAllowsAlertsToBeIssuedWhenADisputeIsRegisteredInTheSystem:
            'Filling in the ticker/CNPJ is not mandatory but allows alerts to be issued when a dispute is registered in the system.',
          fixedCompensation: 'Fixed Compensation',
          history: 'History',
          includeYearsWithIncompleteDataInTheSum:
            'Include years with incomplete data in the sum',
          invalidCnpj: 'Invalid CNPJ',
          latest: 'Latest',
          library: 'Library',
          link: 'Link',
          longTermCompensation: 'Long-term Compensation',
          markAsSeriousControversy:
            'Do you want to mark the result below as a serious company controversy',
          markAsControversial: 'Mark as controversial',

          markControversy: 'Mark controversy',
          marked: 'marked',
          maximumAndMinimumCompensationBoard:
            'Maximum and Minimum Compensation - Board',
          maximumAndMinimumCompensationCouncil:
            'Maximum and Minimum Compensation - Council',
          maximumCompensation: 'Maximum Compensation',
          minimumCompensation: 'Minimum Compensation',
          nameCannotBeEmpty: 'Name cannot be empty',
          nameIsRequired: 'Name is required',
          noResultsFound: 'No results found.',
          overwrite: 'Overwrite',
          peerGroup: 'Peer Group',
          peerGroupIsMandatory: 'Peer group is mandatory',
          prospective: 'Prospective',
          registerNewCompany: 'Register new company',
          remuneration: 'Remuneration',
          removeControversy: 'Remove controversy',
          removedFeminine: 'removed',
          removeFromSeriousControversies:
            "Do you want to remove the result below from the company's serious controversies list",
          rename: 'Rename',
          renameReport: 'Rename report',
          reportLoadedSuccessfully: 'Report loaded successfully',
          reportRenamedSuccessfully: 'Report renamed successfully',
          reportRemovedSuccessfully: 'Report removed successfully',
          reportSavedSuccessfully: 'Report saved successfully',
          reset: 'Reset',
          saveReport: 'Save report',
          searchForControversies: 'Search for controversies',
          searchParameters: 'Search parameters',
          searchText: 'Search text',
          searchType: 'Search type',
          searchInterval: 'Search interval',
          selectCompany: 'Select a company',
          selectSearch: 'Select a search',
          selectTheCompaniesToBeAdded: 'Select the companies to be added...',
          selectThePeers: 'Select the peers...',
          snippet: 'Snippet',
          standard: 'Standard',
          successfully: 'successfully',
          sum: 'Sum',
          thereAreNoControversiesToDisplay:
            'There are no controversies to display',
          thereIsAlreadyACompanyRegisteredWithThisName:
            'A company is already registered with this name',
          title: 'Title',
          totalBoardCompensation: 'Total Board Compensation',
          totalCompensation: 'Total Compensation',
          totalCouncilCompensation: 'Total Council Compensation',
          type: 'Type',
          undoChanges: 'Undo changes',
          unmarkAsControversial: 'Unmark as controversial',
          uploadReport: 'Upload report',
          useOriginalValues: 'Use original values',
          variableCompensation: 'Variable Compensation',
          youHaveNoSavedReports: 'You have no saved reports.',
        },
      },
    },
  },
  pt: {
    translation: {
      Commons: {
        analyse: 'Analisar',
        analysis: 'Análises',
        anUnexpectedErrorHasOccurred: 'Ocorreu um erro inexperado.',
        avarege: 'Média',
        basket: 'Basket',
        baskets: 'Baskets',
        cancel: 'Cancelar',
        category: 'Categoria',
        client: 'Cliente',
        close: 'Fechar',
        climateChange: 'Mudanças climáticas',
        comment: 'Comentário',
        companies: 'Empresas',
        company: 'Empresa',
        controversies: 'Controvérsias',
        confirm: 'Confirmar',
        data: 'Dados',
        dams: 'Barragens',
        delete: 'Excluir',
        diversity: 'Diversidade',
        environmental: 'Ambiental',
        framework: 'Framework',
        help: 'Ajuda',
        goBack: 'Voltar',
        governance: 'Governança',
        indicator: 'Indicador',
        load: 'Carregar',
        manage: 'Gerenciar',
        manageBaskets: 'Gerenciar baskets',
        metric: 'Métrica',
        mode: 'Modo',
        name: 'Nome',
        no: 'Não',
        notes: 'Notas',
        noDataToDisplay: 'Sem dados para exibir',
        overview: 'Visão geral',
        pleaseTryAgain: 'Por favor, tente novamente.',
        pleaseTryAgainLater: 'Por favor, tente novamente mais tarde.',
        qualitative: 'Qualitativo',
        quantitative: 'Quantitativo',
        remuneration: 'Remuneração',
        resourceUsage: 'Uso de recursos',
        save: 'Salvar',
        search: 'Pesquisar',
        sectors: 'Setores',
        settings: 'Configurações',
        social: 'Social',
        source: 'Fonte',
        statistics: 'Estatísticas',
        subsection: 'Subseção',
        themes: 'Temas',
        ticker: 'Ticker',
        values: 'Valores',
        wasteAndEffluentManagement: 'Gestão de resíduos e efluentes',
        weight: 'Peso',
        weights: 'Pesos',
        year: 'Ano',
        yes: 'Sim',
      },
      Components: {
        ESGPlatformHeader: {
          platformHeader: "Plataforma <span className='font-light'>ESG</span>",
        },
        Footer: {
          aboutUs: 'Sobre nós',
          allFunds: 'Todos os fundos',
          esg: 'ESG',
          investmentProcess: 'Processo de Investimento',
          investmentPhilosophy: 'Filosofia de Investimentos',
          howToInvest: 'Como Investir',
          managementReports: 'Relatórios de Gestão',
          regulatoryInformation: 'Informações Regulatórias',
          services: 'Serviços',
          sharesAndReturns: 'Cotas e Rentabilidades',
          the: 'A',
          whoWeAre: 'Quem Somos',
          termsAndConditions: 'Termos & Condições',
          privacyPolicy: 'Política de Privacidade',
          allRightsReserved: 'Todos os direitos reservados',
        },
        LinkGrid: {
          openReferenceForm: 'Abrir formulário de referência',
          referenceFormNotAvailable: 'Formulário de referência não disponível',
        },
        MenuDrawer: {
          customizations: 'Customizações',
          methodologyAndPolicies: 'Metodologia e políticas',
          myAccount: 'Minha conta',
        },
        NotFound: {
          pageNotFound: 'Página não encontrada',
        },
        ReportHeader: {
          addToFavorites: 'Adicionar aos favoritos',
          businessAnalysis: 'Análise Empresarial',
          customAnalysis: 'Análise Personalizada',
          favorite: 'Favoritar',
          removeFromFavorites: 'Remover dos favoritos',
          sectorAnalysis: 'Análise Setorial',
        },
        NotificationButton: {
          notification: 'Notificação',
          disableNotifications: 'Desativar notificações',
          enableNotifications: 'Ativar notificações',
        },
        TopBar: {
          options: {
            customize: 'Personalizar',
            home: 'Início',
          },
          userMenu: {
            notifications: 'Notificações',
            settings: 'Configurações',
          },
        },
      },
      Pages: {
        CompanyReport: {
          answers: 'Respostas',
          basicSanitation: 'Saneamento básico',
          businessAnalysis: 'Análise Empresarial',
          criteria: 'Critérios',
          customization: 'Customização',
          decarbonization: 'Descarbonização',
          justificationDisclaimer: 'Justificativa/Disclaimer',
          eraseCustomizations: 'Apagar customizações',
          goals: 'Metas',
          governance: 'Governança',
          noControversiesForThisCompany:
            'Não há controvérsias registradas para esta empresa.',
          peersAverage: 'Média peers',
          ranking: 'Ranking',
          reportName: 'Nome do relatório',
          score: 'Score',
          sections: 'Seções',
          selectCompanies: 'Selecione empresas...',
          selectCompany: 'Selecione a empresa...',
          selectSector: 'Selecione o setor...',
          social: 'Social',
          topics: 'Tópicos',
        },
        ComparativeReport: {
          averagePay: 'Remuneração média',
          boardIndependence: 'Independência do conselho',
          council: 'Conselho',
          directors: 'Diretores',
          femaleEmployees: 'Funcionárias mulheres',
          womenOnTheBoard: 'Mulheres na diretoria',
          womenOnTheCouncil: 'Mulheres no conselho',
        },
        Login: {
          email: 'Email',
          enter: 'Entrar',
          forgotPassword: 'Esqueci a senha',
          incorrectUsernameOrPassword: 'Usuário ou senha incorretos',
          password: 'Senha',
          register: 'Cadastre-se',
          remindMe: 'Lembre-me',
          tooManyRequests: 'Muitas solicitações.',
          youDoNotHavePermissionToAccessThisPlatform:
            'Você não tem permissão para acessar esta plataforma',
        },
        PersonalizedReport: {
          addFilter: 'Adicionar filtro',
          analysisName: 'Nome da análise',
          analysisSavedSuccessfully: 'Análise salva com sucesso',
          anErrorOccurredWhileDeletingTheAnalysis:
            'Ocorreu um erro ao apagar a análise.',
          anErrorOccurredWhileDeletingTheBasket:
            'Ocorreu um erro ao apagar o basket.',
          anErrorOccurredWhileGeneratingTheShareLink:
            'Ocorreu um erro ao gerar o link de compartilhamento.',
          anErrorOccurredWhileSavingTheAnalysis:
            'Ocorreu um erro ao salvar a análise.',
          anErrorOccurredWhileSavingTheBasket:
            'Ocorreu um erro ao salvar o basket.',
          anErrorOccurredWhileTryingToRemoveTheReport:
            'Ocorreu um erro ao tentar exclluir o relatório',
          anErrorOccurredWhileTryingToRenameTheReport:
            'Ocorreu um erro ao tentar renomear o relatório',
          basketName: 'Nome do basket',
          basketSavedSuccessfully: 'Basket salvo com sucesso',
          basketSharedMessage:
            'Um link compartilhável do basket foi copiado para sua área de transferência',
          calculation: 'Cálculo',
          chooseBasket: 'Escolha o basket',
          chooseCalculation: 'Escolha o cálculo',
          chooseFilter: 'Escolha o filtro',
          chooseIndicator: 'Escolha o indicador',
          choosePonderation: 'Escolha a ponderação',
          chooseSection: 'Escolha a seção',
          chooseSubsection: 'Escolha a subseção',
          chooseValues: 'Escolha os valores',
          createNewBasket: 'Criar novo basket',
          deleteAnalysis: 'Apagar análise',
          deleteBasket: 'Apagar basket',
          filter: 'Filtro',
          manageAnalysis: 'Gerenciar análises',
          ponderation: 'Ponderação',
          reportSharedMessage:
            'Um link compartilhável da análise foi copiado para sua área de transferência',
          saveAnalysis: 'Salvar análise',
          share: 'Compartilhar',
          theAnalysisHasBeenDeleted: 'A análise foi apagada',
          theBasketHasBeenDeleted: 'O basket foi apagado',
          thereAreNoResultsToDisplay: 'Não há resultados para exibir',
          writeTheNameOfTheAnalysis: 'Escreva o nome da análise',
          writeTheNameOfTheBasket: 'Escreva o nome do basket',
        },
        Reports: {
          favoritesFeminine: 'Favoritas',
          favoritesMasculine: 'Favoritos',
          open: 'Abrir',
          recentlyViewedFeminine: 'Vistas Recentemente',
          recentlyViewedMasculine: 'Vistos Recentemente',
          suggestions: 'Sugestões',
        },
        ScoreStatistics: {
          endYear: 'Ano fim',
          excludeCompaniesOrSectorsFromTheFirstSelection:
            'Excluir empresas ou setores da primeira seleção',
          exportAsXlsx: 'Exportar como .xlsx',
          includeSectorsCompaniesOrBaskets:
            'Incluir setores, empresas ou baskets',
          loadingMetrics: 'Carregando metricas...',
          quantitativeValues: 'Valores quantitativos',
          select: 'Selecione...',
          selectIndicatorsForAnalysis: 'Selecionar indicadores para análise',
          startYear: 'Ano início',
          submit: 'Submeter',
        },
        SectoralReports: {
          details: 'Detalhes',
          evolution: 'Evolução',
          thereAreNoControversiesRecordedForCompaniesInThisSector:
            'Não há controvérsias registradas para as empresas deste setor.',
          thereIsNoDataForThisSector: 'Não há dados para este setor.',
        },
        ThemesReport: {
          aDifferentReportWithThisNameAlreadyExists:
            'Já existe um relatório diferente com este nome',
          aReportWithThisNameAlreadyExistsDoYouWantToOverwriteIt:
            'Já existe um relatório salvo com este nome. Deseja sobrescrever?',
          addParameter: 'Adicionar parâmetro',
          addSearch: 'Adicionar busca',
          administrativeBoard: 'Conselho de Administração',
          agoProposal: 'Proposta AGO',
          anErrorOccurredWhileLoadingTheReport:
            'Ocorreu um erro ao carregar o relatório',
          anErrorOccurredWhileSavingTheReport:
            'Ocorreu um erro ao salvar o relatório',
          apply: 'Aplicar',
          averageBoardCompensation: 'Remuneração Média Diretoria',
          averageCompensation: 'Remuneração Média',
          averageCouncilRemuneration: 'Remuneração Média Conselho',
          beforeRegisteringANewCompanyCheckInCompassWhetherTheCompanyAlreadyExistsWithAnAlternativeName:
            'Antes de cadastrar uma nova empresa, verifique no Compass se a empresa já existe com nome alternativo.',
          board: 'Diretoria',
          boardCompensationMktCap: 'Remuneração Diretoria/Mkt Cap',
          boardRemunerationBRLmi: 'Remuneração diretoria (mi BRL)',
          boardRemunerationEBITDA: 'Remuneração Diretoria/EBITDA',
          boardRemunerationProfit: 'Remuneração Diretoria/Lucro',
          cancel: 'Cancelar',
          checkCompanyExistsInCompass:
            'Antes de cadastrar uma nova empresa, verifique no Compass se a empresa já existe com nome alternativo.',
          companySuccessfullyCreated: 'Empresa criada com sucesso',
          compensationBreakdownBoard: 'Breakdown da Remuneração - Diretoria',
          compensationBreakdownCouncil: 'Breakdown da Remuneração - Conselho',
          compensationEBITDA: 'Remuneração/EBITDA',
          compensationMktCap: 'Remuneração/Mkt Cap',
          compensationProfit: 'Remuneração/Lucro',
          controversy: 'Controvérsia',
          controversyLibrary: 'Biblioteca de controvérsias',
          correctData: 'Corrigir dados',
          controversySuccessfullyRemoved: 'Controvérsia removida com sucesso',
          councilRemunerationBRLmi: 'Remuneração conselho (mi BRL)',
          councilRemunerationEBITDA: 'Remuneração Conselho/EBITDA',
          councilRemunerationProfit: 'Remuneração Conselho/Lucro',
          customizeSearch: 'Customizar busca',
          deleteReport: 'Excluir relatório',
          doYouReallyWantToDeleteTheReport: 'Deseja mesmo excluir o relatório',
          downloadDataInXLSX: 'Baixar dados em XLSX',
          enableAlertsWithTickerCNPJ:
            'O preenchimento do ticker/CNPJ não é obrigatório mas permite a emissão de alertas quando uma controvérsia é cadastrada no sistema.',
          enterNewReportName: 'Digite o novo nome do relatório',
          enterTheNameOfTheReportToBeSaved:
            'Digite o nome do relatório a ser salvo',
          estimatesForTheYearOf: 'Estimativas para o ano de',
          fillingInTheTickerCNPJIsNotMandatoryButAllowsAlertsToBeIssuedWhenADisputeIsRegisteredInTheSystem:
            'O preenchimento do ticker/CNPJ não é obrigatório mas permite a emissão de alertas quando uma controvérsia é cadastrada no sistema.',
          fixedCompensation: 'Remuneração Fixa',
          history: 'Histórico',
          includeYearsWithIncompleteDataInTheSum:
            'Incluir anos com dados incompletos na soma',
          invalidCnpj: 'CNPJ inválido',
          latest: 'Últimos',
          library: 'Biblioteca',
          link: 'Link',
          longTermCompensation: 'Remuneração de Longo Prazo',
          markAsSeriousControversy:
            'Deseja marcar o resultado abaixo como controvérsia grave da empresa',
          markAsControversial: 'Marcar como controvérsia',
          markControversy: 'Marcar controvérsia',
          marked: 'marcada',
          maximumAndMinimumCompensationBoard:
            'Remuneração Máxima e Mínima - Diretoria',
          maximumAndMinimumCompensationCouncil:
            'Remuneração Máxima e Mínima - Conselho',
          maximumCompensation: 'Remuneração Máxima',
          minimumCompensation: 'Remuneração Mínima',
          nameCannotBeEmpty: 'O nome não pode ser vazio',
          nameIsRequired: 'Nome é obrigatório',
          noResultsFound: 'Nenhum resultado foi encontrado.',
          overwrite: 'Sobrescrever',
          peerGroup: 'Peer group',
          peerGroupIsMandatory: 'Peer group é obrigatório',
          prospective: 'Prospectivo',
          registerNewCompany: 'Cadastrar nova empresa',
          remuneration: 'Remuneração',
          removeControversy: 'Remover controvérsia',
          removedFeminine: 'removida',
          removeFromSeriousControversies:
            'Deseja remover o resultado abaixo da lista de controvérsias graves da empresa',
          rename: 'Renomear',
          renameReport: 'Renomear relatório',
          reportLoadedSuccessfully: 'Relatório carregado com sucesso',
          reportRenamedSuccessfully: 'Relatório renomeado com sucesso',
          reportRemovedSuccessfully: 'Relatório excluído com sucesso',
          reportSavedSuccessfully: 'Relatório salvo com sucesso',
          reset: 'Resetar',
          saveReport: 'Salvar relatório',
          searchForControversies: 'Busca de controvérsias',
          selectCompany: 'Selecione uma empresa',
          searchInterval: 'Intervalo de busca',
          searchParameters: 'Parâmetros de busca',
          searchText: 'Texto de busca',
          searchType: 'Tipo de busca',
          selectSearch: 'Selecione uma busca',
          selectTheCompaniesToBeAdded:
            'Selecione as empresas a serem somadas...',
          selectThePeers: 'Selecione os peers...',
          snippet: 'Snippet',
          standard: 'Padrão',
          successfully: 'com sucesso',
          sum: 'Soma',
          thereAreNoControversiesToDisplay: 'Não há controvérsias para exibir',
          thereIsAlreadyACompanyRegisteredWithThisName:
            'Já existe uma empresa cadastrada com este nome',
          title: 'Título',
          totalBoardCompensation: 'Remuneração Total Diretoria',
          totalCompensation: 'Remuneração Total',
          totalCouncilCompensation: 'Remuneração Total Conselho',
          type: 'Tipo',
          undoChanges: 'Desfazer alterações',
          unmarkAsControversial: 'Desmarcar como controvérsia',
          uploadReport: 'Carregar relatório',
          useOriginalValues: 'Usar valores originais',
          variableCompensation: 'Remuneração Variável',
          youHaveNoSavedReports: 'Você não possui relatórios salvos.',
        },
      },
    },
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'pt',
    interpolation: { escapeValue: false },
    debug: true,
  })

export default i18n
