import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import LoadingOverlay from '../../../../components/LoadingOverlay'
import ReportHeader from '../../../../components/ReportHeader'
import Spinner from '../../../../components/Spinner'
import { useToast } from '../../../../contexts/Toast'
import { ReportChartData } from '../../containers/PersonalizedReport/typings'
import { ActiveContextProvider } from '../../contexts/Active'
import DebugPanel from '../DebugPanel'
import ManagementModal from '../ManagementModal'
import ReportChart from '../ReportChart'
import SaveModal from './SaveModal'
import Settings from './Settings'

interface Props {
  loadingSettingsData: boolean
  loadingMetricChartData: boolean
  basket: SelectOption | null
  basketOptions: SelectOption[]
  indicator: SelectOption<string[]> | null
  indicatorOptions: SelectOption<string[]>[]
  section: SelectOption<string[]> | null
  sectionOptions: SelectOption<string[]>[]
  subsection: SelectOption<string[]> | null
  subsectionOptions: SelectOption<string[]>[]
  weight: SelectOption | null
  weightOptions: SelectOption[]
  weighting: SelectOption | null
  weightingOptions: SelectOption[]
  reportName: string
  reportMetricData: ReportChartData | null
  reportIndicatorsData: ReportChartData | null
  reportWeightsData: ReportChartData | null
  userReports: { id: string; name: string }[]
  className?: string
  onBasketChange?: (basket: SelectOption | null) => void
  onIndicatorChange?: (indicator: SelectOption<string[]> | null) => void
  onSectionChange?: (section: SelectOption<string[]> | null) => void
  onSubsectionChange?: (subsection: SelectOption<string[]> | null) => void
  onWeightChange?: (weight: SelectOption | null) => void
  onWeightingChange?: (weighting: SelectOption | null) => void
  onSubmit?: () => void
  onReportNameChange?: (reportName: string) => void
  onSave?: () => Promise<void>
  onOpenReport?: (id: string) => void
  onDeleteReport?: (id: string) => Promise<void>
  onAnonymousShare?: () => Promise<string | null>
}

const PersonalizedReport: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { enqueueToast } = useToast()

  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false)
  const [reportsModalOpen, setReportsModalOpen] = useState<boolean>(false)

  const handleSave = async () => {
    await props
      .onSave?.()
      .then(() => {
        enqueueToast({
          type: 'success',
          message: t('Pages.PersonalizedReport.analysisSavedSuccessfully'),
        })
        setSaveModalOpen(false)
      })
      .catch((err) => {
        console.error('Error when saving report:', err)
        enqueueToast({
          type: 'fail',
          message: `${t('Pages.PersonalizedReport.anErrorOccurredWhileSavingTheAnalysis')} ${t('Commons.pleaseTryAgain')}`,
        })
      })
  }

  return (
    <div className={classNames('font-work-sans', props.className)}>
      <ReportHeader
        type="personalized"
        onManage={() => setReportsModalOpen(true)}
      />
      <div className="relative">
        <LoadingOverlay
          className="absolute inset-0 flex items-center justify-center bg-opacity-70"
          type="local"
          show={props.loadingSettingsData}
        />
        <Settings
          basket={props.basket}
          basketOptions={props.basketOptions}
          indicator={props.indicator}
          indicatorOptions={props.indicatorOptions}
          sectionOptions={props.sectionOptions}
          section={props.section}
          subsectionOptions={props.subsectionOptions}
          subsection={props.subsection}
          weight={props.weight}
          weightOptions={props.weightOptions}
          weighting={props.weighting}
          weightingOptions={props.weightingOptions}
          onBasketChange={props.onBasketChange}
          onIndicatorChange={props.onIndicatorChange}
          onSectionChange={props.onSectionChange}
          onSubsectionChange={props.onSubsectionChange}
          onWeightChange={props.onWeightChange}
          onWeightingChange={props.onWeightingChange}
          onSubmit={props.onSubmit}
          onSave={() => setSaveModalOpen(true)}
          onShare={props.onAnonymousShare}
        />
      </div>
      <div className="flex w-full flex-col items-center">
        <div className="w-full max-w-screen-xl pb-8">
          {props.loadingMetricChartData && (
            <div className="flex justify-center py-16">
              <Spinner className="text-jgp-success-dark" size={36} />
            </div>
          )}
          {!props.loadingMetricChartData && props.reportMetricData && (
            <ReportChart
              title={props.reportMetricData.title}
              unit={props.reportMetricData.unit}
              series={props.reportMetricData.series}
            />
          )}
          {!props.loadingMetricChartData && props.reportIndicatorsData && (
            <ActiveContextProvider series={props.reportIndicatorsData.series}>
              <DebugPanel
                className="mt-4"
                title={props.reportIndicatorsData.title}
                unit={props.reportIndicatorsData.unit}
                series={props.reportIndicatorsData.series}
              />
              {props.reportWeightsData && (
                <DebugPanel
                  className="mt-8"
                  title={props.reportWeightsData.title}
                  unit={props.reportWeightsData.unit}
                  series={props.reportWeightsData.series}
                />
              )}
            </ActiveContextProvider>
          )}
          {saveModalOpen && (
            <SaveModal
              reportName={props.reportName}
              onClose={() => setSaveModalOpen(false)}
              onSave={handleSave}
              onReportNameChange={props.onReportNameChange}
            />
          )}
          {reportsModalOpen && (
            <ManagementModal
              type="report"
              loading={props.loadingSettingsData}
              items={props.userReports}
              onClose={() => setReportsModalOpen(false)}
              onDelete={props.onDeleteReport}
              onOpen={props.onOpenReport}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PersonalizedReport
