import 'ag-grid-enterprise'

import type {
  CellClassParams,
  ColDef,
  ValueFormatterParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useWatchLoading } from '../../../../contexts/Loading'
import styles from './ScoreTable.module.css'

interface Props {
  loading: boolean
  companies: string[]
  rowData: RowData[]
  className?: string
}

export interface RowData {
  categoryPath: string[]
  companyScores: Record<string, number | null>
  average: number
  weight: number
}

const scoreFormatter = (params: ValueFormatterParams) =>
  params.value === null || params.value === undefined
    ? '-'
    : params.value.toFixed(0)

const percentFormatter = (params: ValueFormatterParams) => {
  const value = params.value
  return `${(100 * value).toFixed(0)}%`
}

const ScoreTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  useWatchLoading(props.loading)

  const columnDefs: ColDef[] = useMemo(
    () => [
      ...props.companies.map((company) => ({
        field: `companyScores.${company}`,
        headerName: company,
        valueFormatter: scoreFormatter,
        flex: 1,
        minWidth: 110,
      })),
      {
        field: 'average',
        headerName: t('Commons.avarege'),
        valueFormatter: scoreFormatter,
        flex: 1,
        minWidth: 110,
      },
      {
        field: 'weight',
        headerName: t('Commons.weight'),
        valueFormatter: percentFormatter,
        flex: 1,
        minWidth: 110,
      },
    ],
    [props.companies, t]
  )

  return props.loading ? null : (
    <div
      className={classNames(
        'ag-theme-alpine pt-8 pb-20 pl-10 pr-4',
        props.className,
        styles.container
      )}
    >
      <AgGridReact
        rowData={props.rowData}
        getDataPath={(row: RowData) => row.categoryPath}
        autoGroupColumnDef={{
          headerName: t('Commons.category'),
          cellRendererParams: { suppressCount: true },
          autoHeight: true,
          pinned: 'left',
          minWidth: 300,
          cellClass: 'bg-opacity-40',
          cellClassRules: {
            'bg-jgp-success-light': (params: CellClassParams) =>
              params.data.categoryPath[0] === 'Environmental',
            'bg-jgp-info-light': (params: CellClassParams) =>
              params.data.categoryPath[0] === 'Social',
            'bg-jgp-secondary-light': (params: CellClassParams) =>
              params.data.categoryPath[0] === 'Governance',
          },
        }}
        columnDefs={columnDefs}
        treeData
      />
    </div>
  )
}

export default ScoreTable
