import classNames from 'classnames'
import { FunctionComponent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'
import PlainTabs from '../../../../components/PlainTabs'
import RemunerationReportChartSectionContainer from '../../containers/RemunerationReportChartSection'
import {
  CUSTOM_ESTIMATE_YEARS,
  exportDataToXlsx,
  fillCustomEstimates,
} from '../../utils/remuneration'
import RemunerationCustomEstimates from '../RemunerationCustomEstimates'
import RemunerationReportTable from '../RemunerationReportTable'

interface Props {
  className?: string
  customEstimates: Record<string, RemunerationEstimates>
  consolidatedData: RemunerationData
  estimatedData: RemunerationData
  onDataChange?: (data: RemunerationData) => any
  onCustomEstimatesChange?: (
    estimates: Record<string, RemunerationEstimates>
  ) => any
}

type Section = 'historic' | 'prospective' | 'ago'

const RemunerationReport: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState<Section>('historic')

  const data = useMemo(
    () =>
      activeTab === 'historic' ? props.consolidatedData : props.estimatedData,
    [activeTab, props.consolidatedData, props.estimatedData]
  )

  const reportData = useMemo(() => {
    if (activeTab !== 'ago') {
      return data
    }

    return CUSTOM_ESTIMATE_YEARS.reduce(
      (redData, year) =>
        fillCustomEstimates(redData, props.customEstimates[year] ?? {}, year),
      data
    )
  }, [data, activeTab, props.customEstimates])

  return (
    <div className={props.className}>
      <PlainTabs
        tabs={[
          { id: 'historic', label: t('Pages.ThemesReport.history') },
          { id: 'prospective', label: t('Pages.ThemesReport.prospective') },
          { id: 'ago', label: t('Pages.ThemesReport.agoProposal') },
        ]}
        activeId={activeTab}
        onTabChange={(id) => setActiveTab(id)}
      />
      <RemunerationCustomEstimates
        className={classNames('mt-4 mb-8', { hidden: activeTab !== 'ago' })}
        data={data}
        estimates={props.customEstimates}
        onChange={props.onCustomEstimatesChange}
      />
      <RemunerationReportTable
        className="mt-4"
        data={reportData}
        type="council"
        includeEstimates={activeTab !== 'historic'}
      />
      <RemunerationReportTable
        className="mt-4"
        data={reportData}
        type="directors"
        includeEstimates={activeTab !== 'historic'}
      />
      <RemunerationReportChartSectionContainer
        className="mt-8"
        data={reportData}
        includeEstimates={activeTab !== 'historic'}
      />
      <div className="mt-4 flex justify-end">
        <Button
          className="h-8 px-4"
          variant="primary"
          onClick={() => exportDataToXlsx(reportData)}
        >
          {t('Pages.ThemesReport.downloadDataInXLSX')}
        </Button>
      </div>
    </div>
  )
}

export default RemunerationReport
