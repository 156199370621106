import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Tooltip from 'react-tooltip'

import ButtonWithLoading from '../ButtonWithLoading'
import { BlankStarIcon, StarIcon } from '../icons'

interface Props {
  className?: string
  onClick: () => Promise<void>
  isFavorite?: boolean
}

const TOOLTIP_ID = 'favorite'

const FavoriteButton: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <span className={classNames('flex items-center', props.className)}>
      <ButtonWithLoading
        className="focus:outline-none"
        innerClass="flex flex-col items-center text-white md:flex-row md:items-center"
        onClick={props.onClick}
        spinnerProps={{ className: 'text-white', size: 24 }}
        data-tip
        data-for={TOOLTIP_ID}
      >
        {props.isFavorite ? (
          <StarIcon className="mr-1.5 h-6 w-6" />
        ) : (
          <BlankStarIcon className="mr-1.5 h-6 w-6" />
        )}
        <span className="mt-1 text-xs md:text-base lg:mt-0">
          {t('Components.ReportHeader.favorite')}
        </span>
      </ButtonWithLoading>
      <Tooltip id={TOOLTIP_ID} effect="solid" place="bottom">
        {props.isFavorite
          ? t('Components.ReportHeader.removeFromFavorites')
          : t('Components.ReportHeader.addToFavorites')}
      </Tooltip>
    </span>
  )
}

export default FavoriteButton
