import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { uniq } from 'ramda'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { numericFormatter, stringComparator } from '../../utils/tables'
import TableCheckbox from './TableCheckbox'

interface Props {
  series: {
    name: string
    data: { x: number; y: number }[]
  }[]
}

const DebugTable: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const yearList = useMemo(
    () =>
      uniq(
        props.series
          .map((series) => series.data.map((point) => point.x))
          .flat()
          .sort((a, b) => a - b)
      ),
    [props.series]
  )

  const rowData = useMemo(
    () =>
      props.series.map((series) =>
        Object.fromEntries([
          ['name', series.name],
          ...series.data.map(({ x, y }) => [x.toString(), y]),
        ])
      ),
    [props.series]
  )

  const defaultColDef = {
    resizable: true,
    sortable: true,
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: '',
        cellRenderer: TableCheckbox,
        suppressMenu: true,
        sortable: false,
        pinned: 'left',
        minWidth: 40,
        maxWidth: 40,
        cellClass: 'flex items-center justify-center',
      },
      {
        field: 'name',
        headerName: t('Commons.company'),
        pinned: 'left',
        flex: 1.4,
        minWidth: 140,
        comparator: stringComparator,
      },
      ...yearList.map((year) => ({
        field: year.toString(),
        flex: 1,
        minWidth: 120,
        valueFormatter: numericFormatter,
      })),
    ],
    [yearList, t]
  )

  return (
    <div className="ag-theme-alpine-compact h-chart px-2">
      <AgGridReact
        rowData={rowData}
        defaultColDef={defaultColDef}
        overlayNoRowsTemplate={`<span>${t('Commons.noDataToDisplay')}</span>`}
        columnDefs={columnDefs}
      />
    </div>
  )
}

export default DebugTable
