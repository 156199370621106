import type { PermissionName } from '@jgp-er-dev/permissions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { type LinkConfig, LINK_CONFIG } from './LinkConfig'
import PageLink from './PageLink'

interface Props {
  checkPermissions: (permissions: PermissionName[]) => boolean
  closeMenu?: () => void
}

const LinksList: React.FC<Props> = ({ checkPermissions, closeMenu }) => {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <>
      {LINK_CONFIG.map(
        (link: LinkConfig) =>
          (!link.permission || checkPermissions([link.permission])) && (
            <PageLink
              key={link.path}
              label={t(link.labelKey)}
              href={link.path}
              active={location.pathname.startsWith(link.path)}
              onClick={closeMenu}
            />
          )
      )}
    </>
  )
}

export default LinksList
