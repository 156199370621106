import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import NotFound from '../../../../components/NotFound'
import { useWatchLoading } from '../../../../contexts/Loading'
import { sectionIdToLabel } from '../../../../utils'
import { Path } from '../../../../utils/constants'
import ESGSectionOverviewContainer from '../../containers/ESGSectionOverview'
import ESGSubsectionContainer from '../../containers/ESGSubsection'
import { formatRanking } from '../../utils'
import { useCompanyPath } from '../../utils/hooks'
import ScoreCards, { ScoreCardData } from '../ScoreCards'

interface Props {
  loading: boolean
  section: ESGSection
  subsections: { id: string; label: string }[]
  score: number | null
  ranking: number[]
  peerAverageScore: number | null
  weight: number | null
}

const ESGSection: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { companyName, section, subsection } = useCompanyPath()
  useWatchLoading(props.loading)

  const cards: ScoreCardData[] | null = useMemo(
    () =>
      !props.loading
        ? [
            {
              color: 'primary',
              title: t('Pages.CompanyReport.score'),
              value: props.score!.toFixed(0),
              className: 'w-24 p-1',
            },
            {
              color: 'secondary',
              title: t('Pages.CompanyReport.ranking'),
              value: formatRanking(props.ranking[0], props.ranking[1]),
              className: 'w-24 p-1',
            },
            {
              color: 'secondary',
              title: t('Commons.weight'),
              value: (100 * props.weight!).toFixed(0) + '%',
              className: 'w-24 p-1',
            },
            {
              color: 'secondary',
              title: t('Pages.CompanyReport.peersAverage'),
              value: props.peerAverageScore!.toFixed(0),
              className: 'w-24 p-1',
            },
          ]
        : null,
    [
      props.loading,
      props.score,
      props.ranking,
      props.weight,
      props.peerAverageScore,
      t,
    ]
  )

  if (!subsection) {
    return (
      <Redirect
        to={[Path.REPORTS_COMPANY, companyName, section, 'overview'].join('/')}
      />
    )
  }

  const renderBody = () => {
    if (subsection === 'overview') {
      return (
        <ESGSectionOverviewContainer
          className="mt-20"
          section={props.section}
        />
      )
    }

    const label = props.subsections.find((sub) => sub.id === subsection)

    return label ? (
      <ESGSubsectionContainer
        key={label.label}
        className="mt-20"
        section={props.section}
        subsection={label.label}
      />
    ) : (
      <NotFound />
    )
  }

  return props.loading ? null : (
    <div className="px-4 pb-20 font-work-sans md:px-10 lg:pl-10">
      <div className="text-right text-3xl font-semibold md:text-left">
        {sectionIdToLabel(props.section)}
      </div>

      {cards && <ScoreCards cards={cards} />}
      {renderBody()}
    </div>
  )
}

export default ESGSection
