import React from 'react'
import { useTranslation } from 'react-i18next'

import ColumnChart from '../../../../components/ColumnChart'
import { useWatchLoading } from '../../../../contexts/Loading'

interface Props {
  loading: boolean
  company: string
  chartProps: ChartProps[]
  className?: string
}

export interface ChartProps {
  indicatorColumnChartProps: {
    type: 'vertical'
    title: string
    unit: string
    data: {
      name: string
      value: number
    }[]
  }
  vsPeersColumnChartProps: {
    type: 'vertical'
    title: string
    unit: string
    data: {
      name: string
      value: number
    }[]
    average: number
  }
}

const ESGSubsectionQuant: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  useWatchLoading(props.loading)

  return props.loading ? null : (
    <div className={props.className}>
      <div className="font-bold">{t('Commons.quantitative')}</div>
      <div className="mt-10 grid grid-cols-1 gap-y-16 md:grid-cols-2 lg:gap-x-4">
        {props.chartProps.map((indicator) => {
          return (
            <React.Fragment key={indicator.indicatorColumnChartProps.title}>
              <ColumnChart {...indicator.indicatorColumnChartProps} />
              <ColumnChart {...indicator.vsPeersColumnChartProps} />
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default ESGSubsectionQuant
