import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'
import { PlusCircleIcon } from '../../../../components/icons'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import Spinner from '../../../../components/Spinner'
import type { BasketFilter, BasketFilterOption } from '../../typings/baskets'
import FilterItem from './FilterItem'

interface Props {
  loading: boolean
  companyCount: number | null
  filters: BasketFilter[]
  filterOptions: BasketFilterOption[]
  className?: string
  onAddFilter?: () => void
  onChangeFilterFilter?: (id: string, filter: SelectOption) => void
  onChangeFilterMode?: (id: string, mode: SelectOption) => void
  onChangeFilterValues?: (id: string, values: SelectOption[]) => void
  onRemoveFilter?: (id: string) => void
  onSubmit?: () => Promise<void>
  onSave?: () => void
}

const Filters: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false)

  const handleSubmit = () => {
    if (props.onSubmit) {
      setLoadingCompanies(true)
    }
    props.onSubmit?.().finally(() => setLoadingCompanies(false))
  }

  return (
    <div
      className={classNames(
        'relative flex justify-center bg-gray-100 py-8',
        props.className
      )}
    >
      <LoadingOverlay
        className="absolute inset-0 flex items-center justify-center bg-opacity-70"
        type="local"
        show={props.loading}
      />
      <div className="w-full max-w-screen-xl px-4">
        {props.filters.map((filter) => (
          <FilterItem
            className="mb-2"
            key={filter.id}
            filter={filter}
            options={props.filterOptions}
            onChangeFilter={(value) =>
              props.onChangeFilterFilter?.(filter.id, value)
            }
            onChangeMode={(mode) => props.onChangeFilterMode?.(filter.id, mode)}
            onChangeValues={(values) =>
              props.onChangeFilterValues?.(filter.id, values)
            }
            onRemove={() => props.onRemoveFilter?.(filter.id)}
          />
        ))}
        <button
          className={classNames(
            'flex items-center text-sm focus:outline-none',
            { 'mt-4': props.filters.length > 0 }
          )}
          onClick={props.onAddFilter}
        >
          <span>{t('Pages.PersonalizedReport.addFilter')}</span>
          <PlusCircleIcon className="ml-2 h-5 w-5" />
        </button>
        <div className="mt-4 flex flex-wrap gap-4">
          <Button
            className="flex h-8 w-32 items-center justify-center"
            variant="primary"
            disabled={loadingCompanies}
            onClick={handleSubmit}
          >
            <span>{t('Commons.search')}</span>
            {props.companyCount === null ? (
              <Spinner className="ml-2 text-white" size={18} />
            ) : (
              <span className="ml-1">({props.companyCount})</span>
            )}
          </Button>
          <Button
            className="h-8 w-32"
            variant="secondary"
            onClick={props.onSave}
          >
            {t('Commons.save')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Filters
