import classNames from 'classnames'
import dayjs from 'dayjs'
import hash from 'object-hash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Linkify from 'react-linkify'

import ChevronDownIcon from '../icons/ChevronDown'
import ChevronRightIcon from '../icons/ChevronRight'

interface Props {
  title: string
  list: Controversy[]
  className?: string
}

const ControversyList: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <div
      className={classNames(
        'w-full border-2 border-gray-300 p-2',
        props.className
      )}
    >
      <button
        className="w-full focus:outline-none focus-visible:ring-2"
        onClick={() => setExpanded((curValue) => !curValue)}
      >
        <div className="flex items-center">
          {expanded ? (
            <ChevronDownIcon className="h-5 w-5" />
          ) : (
            <ChevronRightIcon className="h-5 w-5" />
          )}
          <span className="ml-1 font-bold">{props.title}</span>
          <span className="ml-1 text-sm">({props.list.length})</span>
        </div>
      </button>
      <div
        className={classNames('overflow-hidden transition-all duration-200', {
          'max-h-0': !expanded,
          'max-h-screen': expanded,
        })}
      >
        {props.list.map((controversy, idx) => (
          <div
            key={hash(controversy)}
            className={classNames(
              'rounded-sm border-2 border-dashed border-gray-300 p-2',
              { 'mt-4': idx > 0 }
            )}
          >
            <div className="italic">{controversy.subsection}</div>
            <div className="whitespace-pre-wrap">{controversy.text}</div>
            <div className="mt-2 text-sm">
              Data: {dayjs(controversy.date).format('MM/YYYY')}
            </div>
            {controversy.source && (
              <Linkify
                componentDecorator={(href, text, key) => (
                  <a key={key} className="hover:underline" href={href}>
                    {text}
                  </a>
                )}
              >
                <div className="text-sm">
                  {t('Commons.source')}: {controversy.source}
                </div>
              </Linkify>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ControversyList
