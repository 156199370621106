import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import XIcon from '../../../../components/icons/X'
import Scrollbar from '../../../../components/Scrollbar'

interface Props {
  companyName: string
  topic: string
  indicator: string
  reason: string
  className?: string
  onClose?: () => void
}

const IndicatorDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const [active, setActive] = useState<boolean>(false)

  useEffect(() => setActive(true), [])

  return (
    <div
      className={classNames(
        'relative flex h-75 flex-col bg-black py-7 pl-7 pr-4.5 font-work-sans text-white transition-opacity duration-200',
        { 'opacity-0': !active },
        props.className
      )}
      onClick={(event) => event.stopPropagation()}
    >
      <div className="mb-2 flex items-start justify-between text-3xl font-semibold">
        <span>{props.companyName}</span>
        <button
          className="ring-blue-600 focus:outline-none focus-visible:ring-2"
          onClick={() => props.onClose?.()}
        >
          <XIcon className="-m-1 h-5 w-5" />
        </button>
      </div>
      <Scrollbar className="w-full flex-grow">
        <div className="text-sm font-semibold text-jgp-success-main">
          {props.topic}
        </div>
        <div className="mt-4 text-[15px]">{props.indicator}</div>
        <div className="mt-4 text-[15px]">
          {t('Pages.CompanyReport.justificationDisclaimer')}: {props.reason}
        </div>
      </Scrollbar>
    </div>
  )
}

export default IndicatorDetails
