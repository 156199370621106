import { RegularChart } from '@jgp-er-dev/charts'
import { FunctionComponent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { colorPalette } from '../../../../utils/constants'

interface Props {
  className?: string
  config: ColumnChartConfig
}

export interface ColumnChartConfig {
  type: 'breakdown'
  chartData: Record<
    string,
    {
      series: {
        name: string
        data: number[]
      }[]
      companies: string[]
    }
  >
  title: string
  unit?: string
  decimals?: number
  stackColumns?: boolean
}

const RemunerationColumnChart: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation()

  const availableYears = useMemo(
    () =>
      Object.keys(props.config.chartData).sort(
        (ya, yb) => parseInt(yb) - parseInt(ya)
      ),
    [props.config.chartData]
  )

  const [year, setYear] = useState<string>(availableYears[0] ?? '')

  const chartProps = useMemo(() => {
    const title = {
      text: props.config.title,
      style: { fontSize: '14px' },
    }
    if (!props.config.chartData[year]) {
      return {
        options: {
          title,
          credits: { text: '' },
        },
      }
    }

    const series = props.config.chartData[year].series.map((s) => ({
      name: s.name,
      data: s.data,
      tooltip: {
        valueSuffix: props.config.unit && ` ${props.config.unit}`,
        valueDecimals: props.config.decimals ?? 0,
      },
    }))

    return {
      options: {
        chart: {
          type: 'column',
          style: { fontFamily: 'Work Sans' },
        },
        colors: [colorPalette[0], colorPalette[4], colorPalette[6]],
        series: series as any,
        yAxis: {
          id: 'default',
          opposite: false,
          min: 0,
          title: {
            align: 'high' as const,
            text: props.config.unit ? `(${props.config.unit})` : undefined,
            rotation: 0,
            offset: 15,
            y: -20,
          },
        },
        xAxis: {
          type: 'category' as const,
          categories: props.config.chartData[year].companies,
        },
        plotOptions: {
          column: {
            stacking: props.config.stackColumns
              ? ('normal' as const)
              : undefined,
          },
        },
        title,
        legend: {
          symbolRadius: 0,
        },
        credits: { text: '' },
      },
    }
  }, [props.config, year])

  return (
    <div className={props.className}>
      <div className="flex justify-end">
        <select
          className="border"
          value={year}
          onChange={(event) => setYear(event.target.value)}
        >
          {availableYears.map((year) => (
            <option key={year} label={year} value={year} />
          ))}
        </select>
      </div>
      <div className="relative grid h-chart grid-cols-1 p-2">
        <RegularChart chartProps={chartProps} />
        {(props.config.chartData[year]?.companies.length ?? 0) === 0 && (
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-sm text-gray-500">
              {t('Commons.noDataToDisplay')}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default RemunerationColumnChart
