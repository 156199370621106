import classNames from 'classnames'
import React from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

const SidebarModal: React.FC<Props> = (props) => {
  return (
    <div
      className={classNames(
        'fixed inset-0 z-50 transition-opacity duration-300',
        props.isOpen
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
      )}
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={props.onClose}
      ></div>

      <div
        className={classNames(
          'relative bg-white w-full h-full lg:w-64 lg:h-auto transition-transform duration-300',
          props.isOpen ? 'translate-x-0' : '-translate-x-full'
        )}
      >
        <button
          className="absolute top-4 right-4 text-black lg:hidden"
          onClick={props.onClose}
        >
          X
        </button>
        <div className="h-full overflow-y-auto">{props.children}</div>
      </div>
    </div>
  )
}

export default SidebarModal
