import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../components/Button'
import {
  PencilAltIcon,
  ShareIcon,
  TrashIcon,
} from '../../../../components/icons'
import Scrollbar from '../../../../components/Scrollbar'
import Spinner from '../../../../components/Spinner'
import { useToast } from '../../../../contexts/Toast'
import { getBasketShareableUrl, getReportShareableUrl } from '../../utils/share'
import Modal from '../Modal'

interface Props {
  type: 'report' | 'basket'
  loading: boolean
  items: Item[]
  onClose?: () => void
  onOpen?: (id: string) => void
  onShare?: (id: string) => void
  onDelete?: (id: string) => Promise<void>
}

interface Item {
  id: string
  name: string
}

const ManagementModal: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  const { enqueueToast } = useToast()

  const [itemBeingDeleted, setItemBeingDeleted] = useState<Item | null>(null)
  const [deleting, setDeleting] = useState<boolean>(false)

  const handleOutsideClick = () => {
    if (itemBeingDeleted) {
      setItemBeingDeleted(null)
    } else {
      props.onClose?.()
    }
  }

  const handleOpen = (id: string) => {
    props.onOpen?.(id)
    props.onClose?.()
  }

  const handleShare = (id: string) => {
    const url =
      props.type === 'report'
        ? getReportShareableUrl(id)
        : getBasketShareableUrl(id)

    navigator.clipboard.writeText(url).then(() => {
      enqueueToast({
        message:
          props.type === 'report'
            ? t('Pages.PersonalizedReport.reportSharedMessage')
            : t('Pages.PersonalizedReport.basketSharedMessage'),
        type: 'success',
      })
    })
  }

  const handleDelete = () => {
    if (!itemBeingDeleted) {
      return
    }

    if (props.onDelete) {
      setDeleting(true)
    }

    props
      .onDelete?.(itemBeingDeleted.id)
      .then(() => {
        setItemBeingDeleted(null)
        enqueueToast({
          message:
            props.type === 'report'
              ? `${t('Pages.PersonalizedReport.theAnalysisHasBeenDeleted')} - ${itemBeingDeleted.name}`
              : `${t('Pages.PersonalizedReport.theBasketHasBeenDeleted')} - ${itemBeingDeleted.name}`,
          type: 'success',
        })
      })
      .catch((err) => {
        console.error('Failed to delete item:', err)
        enqueueToast({
          message:
            props.type === 'report'
              ? `${t('Pages.PersonalizedReport.anErrorOccurredWhileDeletingTheAnalysis')} ${t('Commons.pleaseTryAgain')}`
              : `${t('Pages.PersonalizedReport.anErrorOccurredWhileDeletingTheBasket')} ${t('Commons.pleaseTryAgain')}`,
          type: 'fail',
        })
      })
      .finally(() => setDeleting(false))
  }

  return (
    <Modal
      title={t('Pages.PersonalizedReport.manageAnalysis')}
      onClick={() => itemBeingDeleted && setItemBeingDeleted(null)}
      onClose={handleOutsideClick}
    >
      <div
        className="relative flex h-96 items-center justify-center bg-white p-4"
        onClick={(event) => event.stopPropagation()}
      >
        {itemBeingDeleted && (
          <div className="absolute inset-0 z-10 flex items-center justify-center p-4">
            <div className="relative mx-4 max-w-screen-sm flex-grow rounded-md bg-white p-8 shadow-md">
              {deleting && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-70">
                  <Spinner className="text-jgp-success-dark" size={36} />
                </div>
              )}
              <span>
                {props.type === 'report'
                  ? `${t('Pages.PersonalizesReport.deleteAnalysis')} "${itemBeingDeleted.name}"?`
                  : `${t('Pages.PersonalizesReport.deleteBasket')} "${itemBeingDeleted.name}"?`}
              </span>
              <div className="mt-4 flex items-center justify-center gap-4">
                <Button
                  className="h-8 w-28"
                  variant="primary"
                  onClick={handleDelete}
                >
                  {t('Commons.yes')}
                </Button>
                <Button
                  className="h-8 w-28"
                  variant="secondary"
                  onClick={() => setItemBeingDeleted(null)}
                >
                  {t('Commons.no')}
                </Button>
              </div>
            </div>
          </div>
        )}
        {props.loading ? (
          <Spinner className="text-jgp-success-dark" size={36} />
        ) : (
          <Scrollbar className="h-full">
            {props.items.map((item) => (
              <div
                key={item.id}
                className="flex items-center justify-between border-b border-jgp-secondary-light border-opacity-50 px-4 py-3"
              >
                <button
                  className="text-sm font-semibold focus:outline-none"
                  onClick={() => handleOpen(item.id)}
                >
                  {item.name}
                </button>
                <span className="flex items-center gap-3">
                  <button onClick={() => handleOpen(item.id)}>
                    <PencilAltIcon className="h-6 w-6 text-jgp-secondary-main" />
                  </button>
                  <button onClick={() => handleShare(item.id)}>
                    <ShareIcon className="h-6 w-6 text-jgp-secondary-main" />
                  </button>
                  <button onClick={() => setItemBeingDeleted(item)}>
                    <TrashIcon className="h-6 w-6 text-jgp-secondary-main" />
                  </button>
                </span>
              </div>
            ))}
          </Scrollbar>
        )}
      </div>
    </Modal>
  )
}

export default ManagementModal
